import {
    Component,
    createEffect,
    createMemo,
    For,
    lazy,
    onCleanup,
    onMount,
    Show,
} from 'solid-js'

import maplibregl from 'maplibre-gl'

import './map.scss'

import { MAP_DARK_STYLE } from 'conf/gooje-dark'
import { MAP_LIGHT_STYLE } from 'conf/gooje-light'
// import { MAP_DARK_STYLE } from 'conf/gooje-dark'
import { HorizontalScroll, Logo, setSuggestLogin } from '!/comps'
import { UserLocationFillIcon, UserLocationIcon } from '!/icons'
import { LOCALE } from '!/locale'
import { getDistance } from '!/shared'
import { EATERY_CATEGORIES } from '!/shared/defs'
import { EATERY_CATEGORY_DPY } from '!/shared/dpy'
import { MapChilrenType } from 'index'
import { createStore } from 'solid-js/store'
import {
    location,
    search,
    self,
    setSearch,
    startLiveLocationTracking,
    theme,
} from 'store'
import { eateryMap, fetch_eatery, setEateryMap } from 'store/eatery'

const Eatery = lazy(() => import('./eatery'))
const Search = lazy(() => import('./search'))

export let map: maplibregl.Map | undefined

export function flyToWithCallback(
    target: maplibregl.LngLatLike,
    callback: () => void
) {
    const currentCenter = map.getCenter() // Current map center

    const distance = getDistance(
        currentCenter.lat,
        currentCenter.lng,
        target[1],
        target[0],
        false
    ).value

    // Define the threshold distance (in meters, adjust based on your use case)
    const distanceThreshold = 50 // For example, 50 km

    if (distance > distanceThreshold) {
        // Zoom out first if the distance is greater than the threshold
        map.flyTo({
            center: currentCenter,
            zoom: 8, // Lower zoom level to reduce tile loading
            duration: 500, // Quick zoom-out animation
            essential: true,
        })

        // Once zoom-out is complete, fly to the target
        const onZoomOutEnd = () => {
            map.off('moveend', onZoomOutEnd)
            map.flyTo({
                center: target,
                zoom: 14,
                duration: 500,
                essential: true,
            })

            const onFlyToEnd = () => {
                map.off('moveend', onFlyToEnd)
                callback && callback()
            }

            map.on('moveend', onFlyToEnd)
        }

        map.on('moveend', onZoomOutEnd)
    } else {
        // Fly directly to the target if the distance is within the threshold
        map.flyTo({
            center: target,
            zoom: 14,
            essential: true,
        })

        const onFlyToEnd = () => {
            map.off('moveend', onFlyToEnd)
            callback && callback()
        }

        map.on('moveend', onFlyToEnd)
    }
}

const Map: Component<MapChilrenType> = P => {
    let liveLocationMarker: maplibregl.Marker | null = null
    let customMarker: HTMLElement

    type stateType = {
        isCentering: boolean
        isActive: boolean

        loading: boolean
    }
    const [state, setState] = createStore<stateType>({
        isCentering: false,
        isActive: false,

        loading: true,
    })

    onMount(() => {
        if (window.location.pathname.startsWith('/e/')) {
            let g = window.location.pathname.split('/').filter(Boolean).at(-1)
            fetch_eatery(g)
        }

        window.addEventListener('popstate', e => {
            e.preventDefault()
            if (eateryMap.show) {
                setEateryMap({ show: false })
            }
        })

        map = new maplibregl.Map({
            cooperativeGestures: false,
            container: 'map',
            style: theme() === 'light' ? MAP_LIGHT_STYLE : MAP_DARK_STYLE,
            center: [51.33808559203892, 35.69973203736],
            pitch: 0,
            zoom: 12,
            minZoom: 3,
            maxZoom: 20,
            localIdeographFontFamily: '"Vazir", sans-serif',
            maxBounds: [26, 13, 80, 47],
            attributionControl: false,
            maplibreLogo: false,
        })

        // disable map rotation using right click + drag
        map.dragRotate.disable()

        // disable map rotation using keyboard
        map.keyboard.disable()

        // disable map rotation using touch rotation gesture
        map.touchZoomRotate.disableRotation()

        // map.addControl(
        //     new maplibregl.GeolocateControl({
        //         positionOptions: {
        //             enableHighAccuracy: true,
        //         },
        //         trackUserLocation: true,
        //     })
        // )

        map.on('load', () => set_guest_eateries())
        map.on('touchstart', handleMove)
        map.on('mousedown', handleMove)
        map.on('click', handleClick)
        map.on('zoom', handleZoom)

        onCleanup(() => {
            window.removeEventListener('popstate', e => {
                e.preventDefault()
                if (eateryMap.show) {
                    setEateryMap({ show: false })
                }
            })

            map.off('load', () => set_guest_eateries())
            map.on('touchstart', handleMove)
            map.on('mousedown', handleMove)
            map.off('click', handleClick)
            map.off('zoom', handleZoom)
        })
    })

    createEffect(() => {
        if (!map) return

        map.setStyle(theme() === 'light' ? MAP_LIGHT_STYLE : MAP_DARK_STYLE)

        set_guest_eateries()
    })

    createEffect(() => {
        if (!map) return

        self.guest

        set_guest_eateries()
    })

    function set_guest_eateries() {
        if (self.guest) {
            hideLayer('eatery normal')
            showLayer('eatery guest')
            showLayer('eatery blur')
        } else {
            showLayer('eatery normal')
            hideLayer('eatery guest')
            hideLayer('eatery blur')
        }
    }

    const hideLayer = (layerId: string) => {
        if (map && map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', 'none')
        }
    }
    const showLayer = (layerId: string) => {
        if (map && map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', 'visible')
        }
    }

    function handleZoom() {
        if (!customMarker) return

        const isSmall = customMarker.classList.contains('small')
        const shouldBeSmall = map.getZoom() > 15

        if (shouldBeSmall && !isSmall) {
            customMarker.classList.add('small')
        } else if (!shouldBeSmall && isSmall) {
            customMarker.classList.remove('small')
        }
    }

    function handleMove() {
        if (!state.isCentering) {
            setState({ isActive: false })
        }
    }
    function handleClick(e: maplibregl.MapMouseEvent & Object) {
        if (eateryMap.show) {
            setEateryMap({ show: false })
        }

        let features = map.queryRenderedFeatures(e.point)

        if (!features || !features[0]) return

        let selected_eatery = features.find(e => e.layer.id.includes('eatery'))

        if (!selected_eatery) return

        if (!self.guest) {
            // @ts-ignore
            let gene: string = selected_eatery.properties?.gene

            if (!gene) return

            if (eateryMap.eatery?.gene === gene)
                return setEateryMap({
                    show: true,
                    loading: false,
                })

            fetch_eatery(gene)

            return
        }

        // if was guest
        if (selected_eatery.layer.id.includes('guest')) {
            // @ts-ignore
            let gene: string = selected_eatery.properties?.gene

            fetch_eatery(gene, true)
        } else if (selected_eatery.layer.id.includes('blur')) {
            setEateryMap({
                show: true,
                loading: true,
            })
            setSuggestLogin({
                show: true,
                onReject() {
                    setEateryMap({
                        show: false,
                        loading: false,
                    })
                },
            })
        }
    }

    const categories = createMemo(() =>
        EATERY_CATEGORIES.filter(key => key !== 'unknown')
    )

    // Function to handle user location button click
    const goToUserLocation = (lat: number, lng: number) => {
        if (!map) return

        if (!lat || !lng)
            return setState({ isCentering: false, isActive: false })

        setState({ isCentering: true })

        flyToWithCallback([lng, lat], () => {
            setState({ isActive: true, isCentering: false })
        })

        if (liveLocationMarker) {
            liveLocationMarker.setLngLat([lng, lat])
        } else {
            customMarker = document.createElement('div')
            customMarker.className = 'user-marker-container'

            liveLocationMarker = new maplibregl.Marker({
                element: customMarker,
            })
                .setLngLat([lng, lat])
                .addTo(map)
        }
    }

    const getLocation = () => {
        setState({ isCentering: true })

        startLiveLocationTracking()
            .then(() => {
                goToUserLocation(location.userLat, location.userLng)
            })
            .catch(() => {
                setState({ isCentering: false })
            })
    }

    onCleanup(() => {
        if (liveLocationMarker) {
            liveLocationMarker.remove()
            liveLocationMarker = null
        }
    })

    return (
        <main class='map-container' classList={{ active: P.active }}>
            <Search />

            <div
                class='search-container'
                classList={{ fadeout: eateryMap.fullscreen }}
            >
                <div
                    class='search-input'
                    ontouchend={() => {
                        setEateryMap({ show: false })
                        setSearch({ show: true })
                    }}
                    onclick={() => {
                        if (
                            'ontouchstart' in window ||
                            navigator.maxTouchPoints > 0
                        )
                            return

                        setEateryMap({ show: false })
                        setSearch({ show: true })
                    }}
                >
                    <div class='input-wrapper'>
                        <h3 class='title_small'>جستجو کنید...</h3>
                        <Logo />
                    </div>
                </div>
                <HorizontalScroll class='search-filters'>
                    <For each={categories()}>
                        {(cat, index) => {
                            return (
                                <SearchFilter
                                    icon={EATERY_CATEGORY_DPY[cat].mapIcon}
                                    title={LOCALE.eatery_category(cat)}
                                    id={cat}
                                    index={index()}
                                />
                            )
                        }}
                    </For>
                </HorizontalScroll>
            </div>
            <div class='map-wrapper'>
                <div id='map'></div>
                <button
                    class='user-location'
                    classList={{ animate: state.isCentering }}
                    onclick={async () => {
                        if (state.isActive || state.isCentering) return

                        if (location.userLat && location.userLng) {
                            goToUserLocation(location.userLat, location.userLng)
                        } else {
                            getLocation()
                        }
                    }}
                >
                    <Show when={state.isActive} fallback={<UserLocationIcon />}>
                        <UserLocationFillIcon />
                    </Show>
                </button>
            </div>

            <Eatery />
        </main>
    )
}

interface SearchFilterProps {
    title: string
    id: string
    icon: string
    index: number
}
const SearchFilter: Component<SearchFilterProps> = P => {
    return (
        <button
            class={`search-filter title_smaller ${P.id}`}
            classList={{ active: search.activeCat === P.index }}
            onClick={() => {
                setSearch({ show: true, activeCat: P.index })

                if (eateryMap.show) {
                    setEateryMap({ show: false })
                }
            }}
        >
            <div class='filter-wrapper'>
                <img src={P.icon} loading='lazy' alt='' />
                <div class='holder'>{P.title}</div>
                <div />
            </div>
        </button>
    )
}

const LoadingMap: Component = () => {
    let textRef: HTMLElement
    let loadingRef: HTMLElement

    onMount(() => {
        if (!textRef || !loadingRef) return

        let width = textRef.getBoundingClientRect().width

        let svg = loadingRef.querySelector<HTMLElement>('.logo')

        svg.style.setProperty('--x', `${Math.ceil(width * 1.6)}px`)
    })

    return (
        <div class='loading-map' ref={e => (loadingRef = e)}>
            <div class='loading-wrapper'>
                <Logo />
                <p class='title' ref={e => (textRef = e)}>
                    در حال بارگزاری نقشه...
                </p>
            </div>
        </div>
    )
}

export default Map
