import { SeedError } from './user_defined'

type ErrorMessage = {
    subject: string
    content: string
}

type SeedMessages = {
    [k in SeedError]: ErrorMessage
}

function msg(subject: string, content: string = ''): ErrorMessage {
    return { subject, content }
}

export const SEED_ERROR_MESSAGE_FA: SeedMessages = {
    unknown: msg('خطایی نا معلوم رخ داد'),
    bad_request: msg('در خواست بد'),
    forbidden: msg('درخواست ممنوعه'),
    bad_auth: msg('احراز هویت نامعتبر'),
    not_found: msg('یافت نشد'),
    server_error: msg('خطای سرور'),
    database_error: msg('خطای دیتابیس'),
    change_while_reordering: msg(
        'تغییر در هنگام مرتب کردن',
        'اضافه کردن و یا حذف کردن ایتم ها در هنگام مرتب کردن مجاز نمی باشد'
    ),
    index_out_of_bounds: msg('مورد خارج از محدوده'),
    invalid_image: msg('تصویر نامعتبر'),
    encode_webp_error: msg('تبدیل تصویر به فورمت webp ناموفق بود'),
    end_point_under_maintenance: msg('این بخش درحال تعمیر می باشد'),
}
