import { Component, createSignal, JSX, onCleanup } from 'solid-js'

interface HorizontalScrollProps {
    class?: string // Optional class for styling
    children?: JSX.Element // Explicit type for children
    ref?: (el: HTMLDivElement | undefined) => void
}

export const HorizontalScroll: Component<HorizontalScrollProps> = props => {
    let containerRef: HTMLDivElement | undefined // Reference for the container

    const [isDragging, setIsDragging] = createSignal(false)

    let tooMuchDragged = false
    let startX = 0
    let scrollLeft = 0

    const handleMouseDown = (e: MouseEvent) => {
        if (!containerRef) return
        setIsDragging(true)
        startX = e.pageX - containerRef.offsetLeft
        scrollLeft = containerRef.scrollLeft
        containerRef.style.cursor = 'grabbing'

        tooMuchDragged = false
    }

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging() || !containerRef) return
        e.preventDefault()
        const x = e.pageX - containerRef.offsetLeft
        const deltaX = Math.abs(x - startX) // Track drag distance
        if (deltaX > 30) tooMuchDragged = true
        containerRef.scrollLeft = scrollLeft - (x - startX)
    }

    const handleMouseUpOrLeave = () => {
        if (tooMuchDragged && containerRef) {
            let ac2 = new AbortController()

            containerRef.querySelectorAll('*').forEach(child => {
                const stopEventOnce = (e: Event) => {
                    e.stopImmediatePropagation()
                    e.stopPropagation()
                    e.preventDefault()

                    ac2.abort()
                }
                child.addEventListener('click', stopEventOnce, {
                    once: true,
                    signal: ac2.signal,
                })
            })
        }

        tooMuchDragged = false

        setIsDragging(false)
        if (containerRef) containerRef.style.cursor = 'grab'
    }

    let ac = new AbortController()

    // Attach event listeners
    const addEventListeners = () => {
        if (!containerRef) return
        containerRef.addEventListener('mousedown', handleMouseDown, {
            signal: ac.signal,
        })
        containerRef.addEventListener('mousemove', handleMouseMove, {
            signal: ac.signal,
        })
        containerRef.addEventListener('mouseup', handleMouseUpOrLeave, {
            signal: ac.signal,
        })
        containerRef.addEventListener('mouseleave', handleMouseUpOrLeave, {
            signal: ac.signal,
        })
    }

    // Cleanup event listeners
    const removeEventListeners = () => {
        if (!containerRef) return

        ac.abort()
    }

    onCleanup(removeEventListeners)

    return (
        <div
            ref={e => {
                containerRef = e
                props.ref && props.ref(e)
                addEventListeners()
            }}
            class={props.class}
            style={{
                display: 'flex',
                'overflow-x': 'auto',
            }}
        >
            {props.children}
        </div>
    )
}
