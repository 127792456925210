import { Locale } from './types'

export default {
    FLAG2: {
        idk: 'ناشناخته',
        yes: 'بله',
        no: 'خیر',
    },
    DISH_FLAGS: {
        is_available: 'موجود است',
        is_free: 'رایگان است',
        is_lunch: 'ناهار است',
        is_dinner: 'شام است',
        is_offering: 'نظری است',
        is_breakfast: 'صبحانه است',
        is_spot_rate_price: 'به نرخ روز است',
    },
    DISH_DAILY: {
        is_daily: 'روزانه است',
        in_saturday: 'در شنبه',
        in_sunday: 'در یک شنبه',
        in_monday: 'در دو شنبه',
        in_tuesday: 'در سه شنبه',
        in_wednesday: 'در چهار شنبه',
        in_thursday: 'در پنج شنبه',
        in_friday: 'در جمعه',
    },
    DISH_KIND: {
        unknown: 'نامشحص',
        food: 'غذا',
        pizza: 'پیتزا',
        burger: 'برگر',
        dessert: 'دسر',
        pasta: 'پاستا',
        icecream: 'بستنی',
        seafood: 'غذای دریایی',
        soup: 'سوپ',
        kabab: 'کباب',
        polo: 'پلو',
        chicken: 'مرغ',
        steak: 'استیک',
        sandwich: 'ساندویچ',
        salad: 'سالاد',
        fried: 'سرخ شده',
        fish: 'ماهی',
        drink: 'نوشیدنی',
        tea: 'چای',
        coffee: 'قهوه',
        soda: 'نوشابه',
        mojito: 'موهیتو',
        lemonade: 'لیموناد',
        juice: 'آب میوه',
        chocolate: 'شکلات',
        milk: 'شیر',
        smoothie: 'اسموتی',
        ash: 'آش',
        sushi: 'سوشی',
        meat: 'گوشت',
        noodle: 'نودل',
        sides: 'اضافات',
    },
    BOOL: {
        false: 'خیر',
        true: 'بله',
    },
    // MENU_FILTERS: {
    //     is_private: 'مخفی',
    //     is_available: 'ناموجود',
    //     no_price: 'بدون قیمت',
    //     query: 'جستجو کنید...',
    //     duplicated: 'تکراری',
    //     no_picture: 'بدون عکس',
    // },
    EATERY_TAGS: {
        has_vegetarian_food: 'غذای گیاهی دارد',
        has_vegan_food: 'غذای وگان دارد',
        has_dine_in: 'ناهار خوری دارد',
        has_wifi: 'WiFi دارد',
        has_lunch: 'ناهار دارد',
        has_tv: 'تلویزیون دارد',
        has_solo_dining: 'ناهار خوری انفرادی دارد',
        has_reservations: 'دارای رزرو است',
        has_toilet: 'توالت دارد',
        has_delivery: 'تحویل دارد',
        has_live_music: 'موسیقی زنده دارد',
        has_dinner: 'شام دارد',
        has_drive_through: 'خدمات سواره دارد',
        has_indoor_seating: 'دارای صندلی های داخلی است',
        has_breakfast: 'صبحانه دارد',
        has_halal_food: 'غذای حلال دارد',
        has_private_dining: 'ناهار خوری خصوصی دارد',
        has_parking: 'پارکینگ دارد',
        has_takeaway: 'بیرون بر دارد',
        has_outdoor_seating: 'صندلی در فضای باز دارد',
        is_tourist_friendly: 'دوستانه توریستی است',
        is_wheelchair_accessible: 'ویلچر در دسترس است',
        is_suitable_for_pets: 'برای حیوانات خانگی مناسب است',
        is_suitable_for_children: 'برای کودکان مناسب است',
        is_suitable_for_large_groups: 'برای گروه های بزرگ مناسب است',
        is_suitable_for_family: 'برای خانواده مناسب است',
    },
    SORT_ORDER: {
        // desc: 'نزولی',
        // asc: 'صعودی',
        desc: 'کاهنده',
        asc: 'افزاینده',
        // desc: 'بالا به پایین',
        // asc: 'پایین به بالا',
    },
    FILTER_NUMBER_OPT: {
        gt: 'بیشتر از',
        lt: 'کمتر از',
        eq: 'برابر با',
        ne: 'مخالف با',
        in: 'درون',
        not_in: 'نبودن درون',
    },
    EATERY_SORT: {
        review_count: 'تعداد نظر',
        star_sum: 'جمع ستاره',
        stars: 'ستاره',
    },
    DAYS_OF_WEEK: {
        saturday: 'شنبه',
        sunday: 'یک شنبه',
        monday: 'دوشنبه',
        tuesday: 'سه شنبه',
        wednesday: 'چهارشنبه',
        thursday: 'پنج شنبه',
        friday: 'جمعه',
    },
    EATERY_VIBE: {
        is_industrial: 'is_industrial',
        is_cozy: 'is_cozy',
        is_casual: 'is_casual',
        is_historic: 'is_historic',
        is_beachside: 'is_beachside',
        is_natural: 'is_natural',
        is_modern: 'is_modern',
        is_luxurious: 'is_luxurious',
        is_romantic: 'is_romantic',
    },
    EATERY_FLAGS: {
        is_close: 'بسته است',
        is_for_guest: 'برای مهمان است',
    },
    EATERY_THEME: {
        unknown: 'نامعلوم',
        luxury: 'لاکچری',
        romantic: 'رمانتیک',
        friendly: 'پاتوق',
        family: 'خانوادگی',
        modern: 'مدرن',
        business: 'بیزینسی',
        sports: 'ورزشی',
        mafia: 'مافیا',
        traditional: 'سنتی',
        nature: 'طبیعت',
    },
    EATERY_CATEGORY: {
        unknown: 'نامعلوم',
        cafe: 'کافه',
        restaurant: 'رستوران',
        fast_food: 'فست فود',
        seafood: 'دریایی',
        ice_cream: 'بستنی',
        dizi: 'دیزی',
        ash: 'آش',
        kababi: 'کبابی',
        kalle_pache: 'کله پاچه',
        liver: 'جگر',
    },
    REVIEW_STATE: {
        admin_censored: 'رد توسط ادمین',
        admin_verified: 'تایید توسط ادمین',
        bot_censored: 'رد توسط سیستم',
        bot_verified: 'تایید توسط سیستم',
        default: 'در انتظار تایید',
        staff_censored: 'تایید توسط غذاخوری',
        staff_verified: 'رد توسط غذاخوری',
        user_deleted: 'حذف شده توسط کاربر',
    },
    // UI_EATERY_SECTION: {
    //     overview: 'مشخصات',
    //     menu: 'منو',
    //     map: 'نقشه',
    //     reviews: 'نظرات',
    //     extra: 'اضافات',
    // },
} satisfies Locale
