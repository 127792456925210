// DO NOT EDIT THIS FILE.
// THIS IS A GENERATED FILE AND ANY EDIT WILL BE REPLACED
import * as ud from './user_defined';
export const API_VERSION = '0.0.1';


// DO NOT EDIT THIS FILE
export type EaterySetPhoto = ({
    photo: (File),
    options: EaterySetPhotoOptions,
}
);
// DO NOT EDIT THIS FILE
export type EntityFlags = ({
    is_edited: (boolean),
    is_alive: (boolean),
    is_private: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type LoginInfo = ({
    user: UserInfo,
    created: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type UpdateDishBody = ({
    flags: (((null))|(DishFlags)),
    kind: DishKind,
    price: (number),
    daily: (((null))|(DishDaily)),
    is_private: (boolean),
    name: (string),
    note: (string),
}
);
// DO NOT EDIT THIS FILE
export type UserUpdateBody = ({
    name: (string),
}
);
// DO NOT EDIT THIS FILE
export type AgentInfo = ({
    entity_flags: EntityFlags,
    admin_perms: (((number)[])),
    gene: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type DishKind = ("unknown"|"food"|"pizza"|"burger"|"dessert"|"pasta"|"icecream"|"seafood"|"soup"|"ash"|"kabab"|"polo"|"chicken"|"steak"|"sandwich"|"salad"|"fried"|"fish"|"sushi"|"sides"|"noodle"|"meat"|"drink"|"tea"|"coffee"|"soda"|"mojito"|"lemonade"|"juice"|"chocolate"|"milk"|"smoothie");
// DO NOT EDIT THIS FILE
export type DetailInfo = ({
    entity_flags: EntityFlags,
    length: (number),
    gene: Gene | null,
    next: Gene | null,
    past: Gene | null,
    data: (string),
    buckle: Gene | null,
    flags: DetailFlags,
}
);
// DO NOT EDIT THIS FILE
export type Count = ({
    alive: (number),
    pages: (number),
    total: (number),
}
);
// DO NOT EDIT THIS FILE
export type Action = ("login"|"delete");
// DO NOT EDIT THIS FILE
export type AdminEateryUpdateLocationBody = ({
    zoom: (number),
    longitude: (number),
    latitude: (number),
}
);
// DO NOT EDIT THIS FILE
export type DishDaily = ({
    in_sunday: (boolean),
    in_tuesday: (boolean),
    in_wednesday: (boolean),
    in_saturday: (boolean),
    in_thursday: (boolean),
    is_daily: (boolean),
    in_friday: (boolean),
    in_monday: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type AdminReviewList = ({
    list: ((ReviewInfo[])),
    pond: PondInfo,
}
);
// DO NOT EDIT THIS FILE
export type DishInfo = ({
    note: (string),
    pond: Gene | null,
    entity_flags: EntityFlags,
    eatery: Gene | null,
    flags: DishFlags,
    daily: (((null))|(DishDaily)),
    form: DishForm,
    name: (string),
    kind: DishKind,
    gene: Gene | null,
    photos: (([(Gene | null),(Gene | null),(Gene | null),(Gene | null)])),
    price: (number),
}
);
// DO NOT EDIT THIS FILE
export type EateryFlags = ({
    is_for_guest: (boolean),
    is_close: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type UserReviewList = ({
    list: ((UserReviewInfo[])),
    next: Gene | null,
    past: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type LoginBody = ({
    code: (string),
    phone: (string),
    info: SessionInfo,
    cc: (number),
}
);
// DO NOT EDIT THIS FILE
export type AdminEateryUpdateInfoBody = ({
    tags: (((null))|(EateryTags)),
    opening_hours: ((((Duration[]))[])),
    cc: (number),
    is_private: (boolean),
    name: (string),
    phone: (string),
    category: EateryCategory,
    vibe: (((null))|(EateryVibe)),
    flags: (((null))|(EateryFlags)),
    tables: (number),
    theme: EateryTheme,
}
);
// DO NOT EDIT THIS FILE
export type IssueKind = ((({
    kind: ("unknown"),
}
))|(((DenseArea)&(({
    kind: ("dense_area"),
}
))))|(((CensoredReview)&(({
    kind: ("censored_review"),
}
))))|(((NsfwPhoto)&(({
    kind: ("nsfw_photo"),
}
)))));
// DO NOT EDIT THIS FILE
export type AdminEateryReorderPhotosBody = ({
    photos: (((((null))|(Gene))[])),
}
);
// DO NOT EDIT THIS FILE
export type EaterySort = ("review_count"|"star_sum"|"stars");
// DO NOT EDIT THIS FILE
export type Flag2 = ("idk"|"yes"|"no");
// DO NOT EDIT THIS FILE
export type EateryReviewList = ({
    past: Gene | null,
    list: ((EateryReviewInfo[])),
    next: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type BuckleInfo = ({
    tail: Gene | null,
    belts: (number),
    gene: Gene | null,
    entity_flags: EntityFlags,
    head: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type EaterySearch = ({
    cc: (number),
    review_count: (number),
    is_close: (boolean),
    stars: (number),
    name: (string),
    _geo: ((GeoSearch)),
    phone: (string),
    thumbnail: (string),
    opening_hours: (([((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[]))])),
    theme: (number),
    is_for_guest: (boolean),
    is_private: (boolean),
    category: (number),
    star_sum: (number),
    gene: ((Gene)),
    tables: (number),
}
);
// DO NOT EDIT THIS FILE
export type EateryGuestInfo = ({
    entity_flags: EntityFlags,
    longitude: (number),
    menu: (((null))|(OriginInfo)),
    quality_score: (number),
    photos: (([(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null)])),
    category: EateryCategory,
    stars: (([(number),(number),(number),(number),(number)])),
    tags: EateryTags,
    vibe: EateryVibe,
    tables: (number),
    zoom: (number),
    name: (string),
    cc: (number),
    score: (number),
    flags: EateryFlags,
    opening_hours: (([((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[]))])),
    detail: Gene | null,
    gene: Gene | null,
    phone: (string),
    priority: (number),
    theme: EateryTheme,
    latitude: (number),
    review: ((EateryReviewInfo[])),
    calc_zoom: (number),
}
);
// DO NOT EDIT THIS FILE
export type AdminEaterySearchBody = ({
    sort: (((null))|(EaterySort)),
    filters: ((AdminEateryFilter[])),
    name: (string),
    sort_order: (((null))|(SortOrder)),
}
);
// DO NOT EDIT THIS FILE
export type DishSetPhoto = ({
    options: DishSetPhotoOptions,
    photo: (File),
}
);
// DO NOT EDIT THIS FILE
export type EateryReviewUserInfo = ({
    gene: Gene | null,
    name: (string),
    photo: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type NsfwPhoto = ({
    user: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type Duration = ({
    open: (number),
    close: (number),
}
);
// DO NOT EDIT THIS FILE
export type AdminUserSetAdminPermsBody = ({
    perms: (((number)[])),
}
);
// DO NOT EDIT THIS FILE
export type IssueLabel = ("none"|"bug");
// DO NOT EDIT THIS FILE
export type EaterySubmitBody = ({
    name: (string),
    extra: (((null))|(EateryExtra)),
    category: EateryCategory,
    latitude: (number),
    longitude: (number),
}
);
// DO NOT EDIT THIS FILE
export type ReviewState = ("default"|"bot_censored"|"bot_verified"|"staff_censored"|"staff_verified"|"admin_censored"|"admin_verified"|"user_deleted");
// DO NOT EDIT THIS FILE
export type DishSetPhotoOptions = ({
    put_watermark: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type DishFlags = ({
    is_lunch: Flag2,
    is_breakfast: Flag2,
    is_free: Flag2,
    is_offering: Flag2,
    is_spot_rate_price: Flag2,
    is_dinner: Flag2,
    is_available: Flag2,
}
);
// DO NOT EDIT THIS FILE
export type EEBalad = ({
    poi: ((EEBaladPoi[])),
}
);
// DO NOT EDIT THIS FILE
export type AddDishBody = ({
    eatery: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type RecordInfo = ({
    mime: RecordMime,
    width: (number),
    issue: Gene | null,
    usage: RecordUsage,
    checksum: (string),
    size: (number),
    gene: Gene | null,
    url: (string),
    duration: (number),
    entity_flags: EntityFlags,
    height: (number),
    server: (number),
}
);
// DO NOT EDIT THIS FILE
export type RecordUsage = ("unknown"|"user"|"eatery"|"dish"|"loose");
// DO NOT EDIT THIS FILE
export type Gene = (string);
// DO NOT EDIT THIS FILE
export type AdminEateryInfo = ({
    zoom: (number),
    priority: (number),
    extra: Gene | null,
    detail: Gene | null,
    phone: (string),
    name: (string),
    flags: EateryFlags,
    tables: (number),
    gene: Gene | null,
    category: EateryCategory,
    entity_flags: EntityFlags,
    tags: EateryTags,
    longitude: (number),
    photos: (([(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null)])),
    stars: (([(number),(number),(number),(number),(number)])),
    calc_zoom: (number),
    cc: (number),
    score: (number),
    menu: (((null))|(OriginInfo)),
    opening_hours: (([((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[]))])),
    quality_score: (number),
    theme: EateryTheme,
    latitude: (number),
    vibe: EateryVibe,
    review: (((null))|(OriginInfo)),
}
);
// DO NOT EDIT THIS FILE
export type EateryTheme = ("unknown"|"family"|"romantic"|"sports"|"friendly"|"mafia"|"business"|"modern"|"nature"|"traditional"|"luxury");
// DO NOT EDIT THIS FILE
export type Session = ({
    info: SessionInfo,
    token: (string),
    timestamp: (number),
    ip: (string),
}
);
// DO NOT EDIT THIS FILE
export type EEOpenStreet = ({
    names: (((string)[])),
}
);
// DO NOT EDIT THIS FILE
export type EateryTags = ({
    has_solo_dining: Flag2,
    has_dinner: Flag2,
    has_live_music: Flag2,
    has_dine_in: Flag2,
    has_reservations: Flag2,
    has_toilet: Flag2,
    has_vegetarian_food: Flag2,
    has_lunch: Flag2,
    has_tv: Flag2,
    has_wifi: Flag2,
    has_breakfast: Flag2,
    has_drive_through: Flag2,
    has_outdoor_seating: Flag2,
    is_suitable_for_children: Flag2,
    has_parking: Flag2,
    is_suitable_for_family: Flag2,
    has_delivery: Flag2,
    has_takeaway: Flag2,
    is_suitable_for_pets: Flag2,
    is_tourist_friendly: Flag2,
    is_wheelchair_accessible: Flag2,
    has_halal_food: Flag2,
    has_private_dining: Flag2,
    is_suitable_for_large_groups: Flag2,
    has_indoor_seating: Flag2,
    has_vegan_food: Flag2,
}
);
// DO NOT EDIT THIS FILE
export type PondInfo = ({
    empty: (number),
    alive: (number),
    gene: Gene | null,
    origin: Gene | null,
    entity_flags: EntityFlags,
    past: Gene | null,
    is_free: (boolean),
    next: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type EateryCategory = ("unknown"|"cafe"|"restaurant"|"fast_food"|"seafood"|"ice_cream"|"dizi"|"ash"|"kababi"|"kalle_pache"|"liver");
// DO NOT EDIT THIS FILE
export type RecordMime = ("unknown"|"webp"|"png"|"jpg"|"gif"|"mp4"|"mp3");
// DO NOT EDIT THIS FILE
export type GeoSearch = ({
    lat: (number),
    lng: (number),
}
);
// DO NOT EDIT THIS FILE
export type SessionInfo = ({
    browser: (number),
    os: (number),
    os_version: (number),
    client: (number),
    device: (number),
    client_version: (number),
    browser_version: (number),
}
);
// DO NOT EDIT THIS FILE
export type UserInfo = ({
    gene: Gene | null,
    name: (string),
    created_at: (number),
    review: (((null))|(OriginInfo)),
    session_index: (number),
    cc: (number),
    sessions: ((Session[])),
    agent: (((null))|(AgentInfo)),
    photo: Gene | null,
    phone: (string),
    reviews: (([(number),(number),(number)])),
    is_banned: (boolean),
    entity_flags: EntityFlags,
}
);
// DO NOT EDIT THIS FILE
export type UserReviewEateryInfo = ({
    name: (string),
    gene: Gene | null,
    stars: (((number)[])),
}
);
// DO NOT EDIT THIS FILE
export type AdminUserInfo = ({
    gene: Gene | null,
    review: Gene | null,
    sessions: ((Session[])),
    reviews: (([(number),(number),(number)])),
    phone: (string),
    agent: Gene | null,
    name: (string),
    cc: (number),
    is_banned: (boolean),
    photo: Gene | null,
    created_at: (number),
    entity_flags: EntityFlags,
}
);
// DO NOT EDIT THIS FILE
export type UserReviewInfo = ({
    gene: Gene | null,
    timestamp: (number),
    user: Gene | null,
    entity_flags: EntityFlags,
    star: (number),
    detail: Gene | null,
    cousin: Gene | null,
    issue: Gene | null,
    eatery: (((null))|(UserReviewEateryInfo)),
    pond: Gene | null,
    state: ReviewState,
    summary: (string),
}
);
// DO NOT EDIT THIS FILE
export type VerificationData = ({
    action: Action,
    cc: (number),
    phone: (string),
}
);
// DO NOT EDIT THIS FILE
export type SortOrder = ("desc"|"asc");
// DO NOT EDIT THIS FILE
export type ReviewInfo = ({
    pond: Gene | null,
    state: ReviewState,
    eatery: Gene | null,
    entity_flags: EntityFlags,
    timestamp: (number),
    star: (number),
    user: Gene | null,
    detail: Gene | null,
    issue: Gene | null,
    cousin: Gene | null,
    summary: (string),
    gene: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type EEBaladPoi = ({
    minzoom: (number),
    reviews: (number),
    score: (number),
    token: (string),
}
);
// DO NOT EDIT THIS FILE
export type VerificationResponse = ({
    expires: (number),
    action: Action,
}
);
// DO NOT EDIT THIS FILE
export type AdminEateryFilter = ((({
    kind: ("is_close"),
    value: (boolean),
}
))|(({
    kind: ("is_private"),
    value: (boolean),
}
))|(({
    kind: ("is_for_guest"),
    value: (boolean),
}
))|(({
    value: FilterNumber_u8,
    kind: ("category"),
}
))|(({
    kind: ("theme"),
    value: FilterNumber_u8,
}
))|(({
    value: FilterNumber_f32,
    kind: ("stars"),
}
))|(({
    value: ({
    lat: (number),
    lng: (number),
    distance_in_meters: (number),
}
),
    kind: ("geo_radius"),
}
))|(({
    kind: ("or"),
}
))|(({
    kind: ("and"),
}
))|(({
    kind: ("group"),
    value: ((AdminEateryFilter[])),
}
)));
// DO NOT EDIT THIS FILE
export type DenseArea = ({
    longitude: (number),
    latitude: (number),
}
);
// DO NOT EDIT THIS FILE
export type CensoredReview = ({
    review: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type EateryVibe = ({
    is_historic: (boolean),
    is_modern: (boolean),
    is_romantic: (boolean),
    is_luxurious: (boolean),
    is_industrial: (boolean),
    is_cozy: (boolean),
    is_natural: (boolean),
    is_beachside: (boolean),
    is_casual: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type SnakeInfo = ({
    is_free: (boolean),
    entity_flags: EntityFlags,
    length: (number),
    gene: Gene | null,
    capacity: (number),
}
);
// DO NOT EDIT THIS FILE
export type AddReviewBody = ({
    detail: (string | null),
    star: (number),
}
);
// DO NOT EDIT THIS FILE
export type AdminEateryItem = ({
    latitude: (number),
    category: EateryCategory,
    name: (string),
    quality_score: (number),
    stars: (([(number),(number),(number),(number),(number)])),
    tags: EateryTags,
    entity_flags: EntityFlags,
    vibe: EateryVibe,
    tables: (number),
    detail: Gene | null,
    cc: (number),
    photos: (([(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null)])),
    priority: (number),
    phone: (string),
    calc_zoom: (number),
    review: Gene | null,
    score: (number),
    extra: Gene | null,
    opening_hours: (([((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[]))])),
    gene: Gene | null,
    zoom: (number),
    longitude: (number),
    menu: Gene | null,
    theme: EateryTheme,
    flags: EateryFlags,
}
);
// DO NOT EDIT THIS FILE
export type DishList = ({
    past: Gene | null,
    list: ((DishInfo[])),
    next: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type EateryFilter = ((({
    value: (boolean),
    kind: ("is_close"),
}
))|(({
    kind: ("category"),
    value: FilterNumber_u8,
}
))|(({
    kind: ("theme"),
    value: FilterNumber_u8,
}
))|(({
    value: FilterNumber_f32,
    kind: ("stars"),
}
))|(({
    kind: ("geo_radius"),
    value: ({
    lat: (number),
    lng: (number),
    distance_in_meters: (number),
}
),
}
))|(({
    kind: ("or"),
}
))|(({
    kind: ("and"),
}
))|(({
    kind: ("group"),
    value: ((EateryFilter[])),
}
)));
// DO NOT EDIT THIS FILE
export type EaterySetPhotoOptions = ({
    put_watermark: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type IssueInfo = ((IssueKind)&(({
    label: IssueLabel,
    gene: Gene | null,
    entity_flags: EntityFlags,
}
)));
// DO NOT EDIT THIS FILE
export type IssueUpdateBody = ((IssueKind)&(({
    label: IssueLabel,
}
)));
// DO NOT EDIT THIS FILE
export type UpdatePhoto = ({
    photo: (File),
}
);
// DO NOT EDIT THIS FILE
export type DetailFlags = ({
    is_end: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type AddRecord = ({
    record: (File),
}
);
// DO NOT EDIT THIS FILE
export type AdminDishList = ({
    pond: PondInfo,
    list: ((DishInfo[])),
}
);
// DO NOT EDIT THIS FILE
export type DishForm = ("unknown"|"food"|"drink");
// DO NOT EDIT THIS FILE
export type EateryExtra = ({
    other_locations: (((([(number), (number)]))[])),
    other_names: (((string)[])),
    balad: ((EEBalad)),
    links: (((string)[])),
    openstreet: ((EEOpenStreet)),
    other_categories: (((number)[])),
}
);
// DO NOT EDIT THIS FILE
export type EateryInfo = ({
    score: (number),
    vibe: EateryVibe,
    opening_hours: (([((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[])),((Duration[]))])),
    calc_zoom: (number),
    detail: Gene | null,
    cc: (number),
    photos: (([(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null),(Gene | null)])),
    zoom: (number),
    category: EateryCategory,
    tags: EateryTags,
    stars: (([(number),(number),(number),(number),(number)])),
    tables: (number),
    phone: (string),
    theme: EateryTheme,
    entity_flags: EntityFlags,
    latitude: (number),
    priority: (number),
    menu: (((null))|(OriginInfo)),
    review: (((null))|(OriginInfo)),
    gene: Gene | null,
    name: (string),
    longitude: (number),
    quality_score: (number),
    flags: EateryFlags,
}
);
// DO NOT EDIT THIS FILE
export type UpdateReviewBody = ({
    state: ReviewState,
}
);
// DO NOT EDIT THIS FILE
export type FilterNumber_f32 = ((({
    opt: ("gt"),
    val: (number),
}
))|(({
    val: (number),
    opt: ("lt"),
}
))|(({
    opt: ("eq"),
    val: (number),
}
))|(({
    val: (number),
    opt: ("ne"),
}
))|(({
    val: (((number)[])),
    opt: ("in"),
}
))|(({
    opt: ("not_in"),
    val: (((number)[])),
}
)));
// DO NOT EDIT THIS FILE
export type FilterNumber_u8 = ((({
    opt: ("gt"),
    val: (number),
}
))|(({
    val: (number),
    opt: ("lt"),
}
))|(({
    val: (number),
    opt: ("eq"),
}
))|(({
    val: (number),
    opt: ("ne"),
}
))|(({
    opt: ("in"),
    val: (((number)[])),
}
))|(({
    opt: ("not_in"),
    val: (((number)[])),
}
)));
// DO NOT EDIT THIS FILE
export type EaterySearchBody = ({
    sort: (((null))|(EaterySort)),
    filters: ((EateryFilter[])),
    sort_order: (((null))|(SortOrder)),
    name: (string),
}
);
// DO NOT EDIT THIS FILE
export type AdminUserUpdateBody = ({
    delete_photo: (boolean),
    name: (string),
    is_banned: (boolean),
}
);
// DO NOT EDIT THIS FILE
export type IssueAddBody = ((IssueKind));
// DO NOT EDIT THIS FILE
export type EateryReviewInfo = ({
    issue: Gene | null,
    eatery: Gene | null,
    star: (number),
    detail: Gene | null,
    gene: Gene | null,
    state: ReviewState,
    entity_flags: EntityFlags,
    summary: (string),
    timestamp: (number),
    user: (((null))|(EateryReviewUserInfo)),
    cousin: Gene | null,
    pond: Gene | null,
}
);
// DO NOT EDIT THIS FILE
export type OriginInfo = ({
    tail: Gene | null,
    entity_flags: EntityFlags,
    items: (number),
    owner: Gene | null,
    gene: Gene | null,
    head: Gene | null,
    ponds: (number),
}
);
// DO NOT EDIT THIS FILE
/**
Get Detail

*/
export async function api_guest_eateries_detail_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<string>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/guest/eateries/${gene}/detail/`,
            method: "GET",
            params: {  },
            type: "text",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<string> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update Photo

*/
export async function api_user_photo_put (body:UpdatePhoto,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(RecordInfo)>> {
    
    let data = new FormData;
data.set('photo', body.photo);

    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/photo/`,
            method: "PUT",
            params: {  },
            type: "json",
            headers: {},
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(RecordInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Delete Photo

*/
export async function api_user_photo_del (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/photo/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_eateries_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Submit

*/
export async function api_bot_eateries_submit_post (body:EaterySubmitBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/bot/eateries/submit/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_issues_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Pond List

*/
export async function api_admin_dishes_pond_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminDishList)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/pond/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminDishList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Menu Get

*/
export async function api_guest_eateries_menu_get (params: {gene:Gene,pond:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishList)>> {
    let { gene,pond } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/guest/eateries/${gene}/menu/${pond}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_user_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(UserInfo)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(UserInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update

*/
export async function api_user_patch (body:UserUpdateBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update

*/
export async function api_admin_reviews_patch (params: {gene:Gene,},body:UpdateReviewBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(ReviewInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/reviews/${gene}/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(ReviewInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_admin_users_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(UserInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(UserInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update

*/
export async function api_admin_users_patch (params: {gene:Gene,},body:AdminUserUpdateBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminUserInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/${gene}/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminUserInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get Review Detail

*/
export async function api_eateries_reviews_detail_get (params: {gene:Gene,review:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<string>> {
    let { gene,review } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/reviews/${review}/detail/`,
            method: "GET",
            params: {  },
            type: "text",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<string> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_admin_eateries_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_extras_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/extras/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_guest_eateries_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(EateryGuestInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/guest/eateries/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(EateryGuestInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_users_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((AdminUserInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((AdminUserInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Login

*/
export async function api_user_login_post (body:LoginBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(LoginInfo)>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/login/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(LoginInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_dishes_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Admin Ui

*/
export async function api_admin_auth_admin_ui_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/auth/admin-ui/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_reviews_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((ReviewInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/reviews/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((ReviewInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Menu Get

*/
export async function api_eateries_menu_get (params: {gene:Gene,pond:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishList)>> {
    let { gene,pond } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/menu/${pond}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_details_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/details/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update Location

*/
export async function api_admin_eateries_location_patch (params: {gene:Gene,},body:AdminEateryUpdateLocationBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryItem)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/location/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryItem)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_issues_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((IssueInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((IssueInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Add

*/
export async function api_admin_issues_post (body:IssueAddBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(IssueInfo)>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(IssueInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Delete

*/
export async function api_admin_dishes_del (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/${gene}/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update

*/
export async function api_admin_dishes_patch (params: {gene:Gene,},body:UpdateDishBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/${gene}/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_eateries_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(EateryInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(EateryInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Delete Session

*/
export async function api_user_sessions_del (params: {sid:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    let { sid } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/sessions/${sid}/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Set Photo

*/
export async function api_admin_eateries_photos_put (params: {gene:Gene,pdx:(number),},body:EaterySetPhoto,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(RecordInfo)>> {
    let { gene,pdx } = params;
    let data = new FormData;
data.set('photo', body.photo);
data.set('options', new Blob([JSON.stringify(body.options)], { type: 'application/json' }));

    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/photos/${pdx}/`,
            method: "PUT",
            params: {  },
            type: "json",
            headers: {},
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(RecordInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Del Photo

*/
export async function api_admin_eateries_photos_del (params: {gene:Gene,pdx:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryItem)>> {
    let { gene,pdx } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/photos/${pdx}/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryItem)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_users_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_dishes_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((DishInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((DishInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Add

*/
export async function api_admin_dishes_post (body:AddDishBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishInfo)>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Pond List

*/
export async function api_admin_reviews_pond_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminReviewList)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/reviews/pond/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminReviewList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Set Detail

*/
export async function api_admin_eateries_detail_put (params: {gene:Gene,},body:string,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    let { gene } = params;
    let data = body;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/detail/`,
            method: "PUT",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'text/plain' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Del Detail

*/
export async function api_admin_eateries_detail_del (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/detail/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List Buckles

*/
export async function api_admin_extras_buckles_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((BuckleInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/extras/buckles/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((BuckleInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_admin_extras_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<string>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/extras/${gene}/`,
            method: "GET",
            params: {  },
            type: "text",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<string> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get Reviews

*/
export async function api_eateries_reviews_get (params: {gene:Gene,pond:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(EateryReviewList)>> {
    let { gene,pond } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/reviews/${pond}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(EateryReviewList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Search

*/
export async function api_guest_eateries_search_post (body:EaterySearchBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((EaterySearch[])))>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/guest/eateries/search/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((EaterySearch[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Logout

*/
export async function api_user_logout_post (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/logout/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Set Admin Perms

*/
export async function api_admin_users_admin_perms_patch (params: {gene:Gene,},body:AdminUserSetAdminPermsBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AgentInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/${gene}/admin-perms/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AgentInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Search

*/
export async function api_admin_eateries_search_post (body:AdminEaterySearchBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((EaterySearch[])))>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/search/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((EaterySearch[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_details_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((DetailInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/details/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((DetailInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Admin List

*/
export async function api_admin_users_admins_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((AdminUserInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/users/admins/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((AdminUserInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Add Review

*/
export async function api_eateries_reviews_post (params: {gene:Gene,},body:AddReviewBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(ReviewInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/reviews/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(ReviewInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_admin_issues_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(IssueInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/${gene}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(IssueInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Delete

*/
export async function api_admin_issues_del (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(IssueInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/${gene}/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(IssueInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update

*/
export async function api_admin_issues_patch (params: {gene:Gene,},body:IssueUpdateBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(IssueInfo)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/issues/${gene}/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(IssueInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Delete Review Detail

*/
export async function api_user_reviews_detail_del (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(ReviewInfo)>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/reviews/${gene}/detail/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(ReviewInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Reviews

*/
export async function api_user_reviews_get (params: {pond:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(UserReviewList)>> {
    let { pond } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/user/reviews/${pond}/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(UserReviewList)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List Buckles

*/
export async function api_admin_details_buckles_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((BuckleInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/details/buckles/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((BuckleInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_eateries_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((AdminEateryItem[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((AdminEateryItem[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Add

*/
export async function api_admin_eateries_post (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryItem)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryItem)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get

*/
export async function api_admin_details_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<string>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/details/${gene}/`,
            method: "GET",
            params: {  },
            type: "text",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<string> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Search

*/
export async function api_eateries_search_post (body:EaterySearchBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((EaterySearch[])))>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/search/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((EaterySearch[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Reorder Photos

*/
export async function api_admin_eateries_photos_reorder_patch (params: {gene:Gene,},body:AdminEateryReorderPhotosBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryItem)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/photos-reorder/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryItem)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_records_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/records/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Get Detail

*/
export async function api_eateries_detail_get (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<string>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/eateries/${gene}/detail/`,
            method: "GET",
            params: {  },
            type: "text",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<string> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Set Extra

*/
export async function api_admin_eateries_extra_put (params: {gene:Gene,},body:EateryExtra,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/extra/`,
            method: "PUT",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Del Extra

*/
export async function api_admin_eateries_extra_del (params: {gene:Gene,},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<void>> {
    let { gene } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/extra/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<void> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Set Photo

*/
export async function api_admin_dishes_photos_put (params: {gene:Gene,pdx:(number),},body:DishSetPhoto,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(RecordInfo)>> {
    let { gene,pdx } = params;
    let data = new FormData;
data.set('options', new Blob([JSON.stringify(body.options)], { type: 'application/json' }));
data.set('photo', body.photo);

    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/${gene}/photos/${pdx}/`,
            method: "PUT",
            params: {  },
            type: "json",
            headers: {},
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(RecordInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Del Photo

*/
export async function api_admin_dishes_photos_del (params: {gene:Gene,pdx:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(DishInfo)>> {
    let { gene,pdx } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/dishes/${gene}/photos/${pdx}/`,
            method: "DELETE",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(DishInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Verification

*/
export async function api_verification_post (body:VerificationData,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(VerificationResponse)>> {
    
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/verification/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(VerificationResponse)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
List

*/
export async function api_admin_records_list (params: {page:(number),},override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(((RecordInfo[])))>> {
    let { page } = params;
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/records/`,
            method: "GET",
            params: { page },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(((RecordInfo[])))> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Add

*/
export async function api_admin_records_post (body:AddRecord,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(RecordInfo)>> {
    
    let data = new FormData;
data.set('record', body.record);

    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/records/`,
            method: "POST",
            params: {  },
            type: "json",
            headers: {},
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(RecordInfo)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Count

*/
export async function api_admin_reviews_count_get (override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(Count)>> {
    
    let data = undefined;
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/reviews/count/`,
            method: "GET",
            params: {  },
            type: "json",
            headers: { 'Content-Type': '' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(Count)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
// DO NOT EDIT THIS FILE
/**
Update Info

*/
export async function api_admin_eateries_info_patch (params: {gene:Gene,},body:AdminEateryUpdateInfoBody,override: Partial<ud.HttpxProps> = {}) : Promise<ud.Result<(AdminEateryItem)>> {
    let { gene } = params;
    let data = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        ud.httpx({
            url: `/api/admin/eateries/${gene}/info/`,
            method: "PATCH",
            params: {  },
            type: "json",
            headers: { 'Content-Type': 'application/json' },
            data,
            reject,
            onLoad(x) {
                resolve({
                    // ok: x.status == 200,
                    status: x.status,
                    body: x.response,
                    ok(): this is ud.Ok<(AdminEateryItem)> {
                        return this.status == 200
                    },
                    err(): this is ud.Err {
                        return !this.ok()
                    },
                })
            },
            ...override
        })
    })
}
