import { MapFillIcon, MapIcon, PersonFillSvg, PersonSvg } from '!/icons/navbar'
import { activeTab, setActiveSection, SetActivetab, setSearch } from '!/store'
import { eateryMap, setEateryMap } from '!/store/eatery'
import { Component, createEffect, on, onCleanup, onMount } from 'solid-js'

import './style/navbar.scss'

const Navbar: Component<{}> = props => {
    let circleRef: HTMLElement | null
    let root: HTMLElement | null

    onMount(() => {
        root = document.querySelector<HTMLElement>('body .root')
        circleRef = document.querySelector<HTMLElement>('div.circles')

        window.addEventListener('resize', () => {
            set_svg()
        })

        onCleanup(() => {
            window.removeEventListener('resize', () => {
                set_svg()
            })
        })
    })

    createEffect(
        on(
            () => activeTab(),
            () => {
                set_svg()
            }
        )
    )

    function set_svg() {
        let space = 0

        if (!circleRef || !root) return

        let activeNav = root.querySelector<HTMLElement>('.nav-tab.active')

        if (!activeNav) return

        let svg = activeNav.querySelector<HTMLElement>('svg')
        let parent = activeNav.querySelector<HTMLElement>('svg').parentElement
        let svgLeft = svg.getBoundingClientRect().left

        let rootLet = root.getBoundingClientRect().left

        if (innerWidth >= 768) {
            // console.log(svgTop, rootHeight)
            let sizeDiff =
                parent.getBoundingClientRect().height -
                circleRef.getBoundingClientRect().height

            space = parent.offsetTop + sizeDiff / 2

            return (circleRef.style.transform = `translateY(${
                space
            }px) translateX(0px)  translateZ(0)`)
        }

        space = svgLeft - rootLet

        return (circleRef.style.transform = `translateX(${
            space - Math.ceil(svg.getBoundingClientRect().width / 2)
        }px) translateY(-38%)  translateZ(0)`)
    }

    function set_detault() {
        setSearch({ show: false })
        setEateryMap({
            show: false,
            fullscreen: false,
        })
        setActiveSection('')
    }

    return (
        <nav class='nav-container' classList={{ hide: eateryMap.show }}>
            <div class='circles'>
                <div class='circle'></div>
                <div class='semi-circle'></div>
            </div>
            <button
                onclick={() => {
                    SetActivetab('map')
                    set_detault()
                }}
                class='nav-tab icon'
                classList={{ active: activeTab() === 'map' }}
            >
                <MapFillIcon classList='map-icon fill' />
                <MapIcon size={30} />
            </button>
            <button
                onclick={() => {
                    SetActivetab('account')
                    set_detault()
                }}
                class='nav-tab icon'
                classList={{ active: activeTab() === 'account' }}
            >
                <PersonFillSvg classList='account-icon fill' />
                <PersonSvg size={30} />
            </button>
        </nav>
    )
}

export default Navbar
