import { StyleSpecification } from 'maplibre-gl'

export const MAP_LIGHT_STYLE: StyleSpecification = {
    version: 8,
    name: 'Gooje',
    metadata: { 'maputnik:renderer': 'mlgljs' },
    sources: {
        'natural-earth': {
            type: 'vector',
            url: 'https://gooje.app/base-map/natural-earth',
        },
        'natural-earth-raster': {
            type: 'raster',
            url: 'https://gooje.app/base-map/natural-earth-raster',
        },
        iran: { type: 'vector', url: 'https://gooje.app/base-map/iran' },
        gooje: {
            type: 'vector',
            url: 'https://gooje.app/gooje-tilejson/',
        },
    },
    sprite: 'https://gooje.app/base-map/sprite/light',
    glyphs: 'https://gooje.app/base-map/font/{fontstack}/{range}',
    layers: [
        {
            id: 'background',
            type: 'background',
            minzoom: 3,
            paint: { 'background-color': '#f5f3f3', 'background-opacity': 1 },
        },
        {
            id: 'fill aeroway',
            type: 'fill',
            source: 'iran',
            'source-layer': 'aeroway',
            minzoom: 10,
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#dbdde1',
                'fill-opacity': {
                    stops: [
                        [11, 0],
                        [11.5, 1],
                    ],
                },
            },
        },
        {
            id: 'fill military',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            minzoom: 11,
            filter: ['all', ['==', 'class', 'military']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(207, 207, 207, 1)',
                'fill-opacity': {
                    stops: [
                        [11, 0],
                        [11.5, 1],
                        [22, 1],
                    ],
                },
            },
        },
        {
            id: 'fill commercial',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            minzoom: 12,
            filter: ['any', ['==', 'class', 'commercial']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(207, 207, 207, 1)',
                'fill-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                        [22, 1],
                    ],
                },
            },
        },
        {
            id: 'fill college',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            minzoom: 12,
            filter: [
                'any',
                ['==', 'class', 'college'],
                ['==', 'class', 'university'],
                ['==', 'class', 'school'],
            ],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(240, 232, 198, 1)',
                'fill-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                    ],
                },
            },
        },
        {
            id: 'fill hospital',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            minzoom: 12,
            filter: ['all', ['==', 'class', 'hospital']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(207, 207, 207, 1)',
                'fill-antialias': false,
                'fill-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                    ],
                },
            },
        },
        {
            id: 'fill railway',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            filter: ['all', ['==', 'class', 'railway']],
            layout: { visibility: 'visible' },
            paint: { 'fill-color': '#afeba3' },
        },
        {
            id: 'fill park',
            type: 'fill',
            source: 'iran',
            'source-layer': 'park',
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#afeba3',
                'fill-opacity': {
                    stops: [
                        [11, 0],
                        [11.5, 1],
                    ],
                },
            },
        },
        {
            id: 'fill grass',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landcover',
            filter: [
                'any',
                ['==', 'class', 'grass'],
                ['==', 'class', 'farmland'],
                ['==', 'class', 'rock'],
            ],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(112, 220, 91, 1)',
                'fill-opacity': {
                    stops: [
                        [7, 0],
                        [7.5, 1],
                    ],
                },
            },
        },
        {
            id: 'fill woods',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landcover',
            filter: ['all', ['==', 'class', 'wood']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'rgba(161, 232, 147, 1)',
                'fill-translate-anchor': 'map',
                'fill-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'fill football',
            type: 'fill',
            source: 'iran',
            'source-layer': 'landuse',
            filter: ['any', ['==', 'class', 'pitch']],
            layout: { visibility: 'visible' },
            paint: { 'fill-color': 'rgba(182, 238, 157, 1)' },
        },
        {
            id: 'fill lake',
            type: 'fill',
            source: 'iran',
            'source-layer': 'water',
            filter: ['all', ['==', 'class', 'lake'], ['!has', 'intermittent']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#b3dcef',
                'fill-antialias': false,
                'fill-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'fill earth bg',
            type: 'raster',
            source: 'natural-earth-raster',
            maxzoom: 6,
            layout: { visibility: 'visible' },
            paint: {
                'raster-opacity': {
                    base: 1,
                    stops: [
                        [4, 1],
                        [6, 0],
                    ],
                },
                'raster-contrast': 0,
            },
        },
        {
            id: 'fill earth water_shadow',
            type: 'fill',
            source: 'natural-earth',
            'source-layer': 'water',
            maxzoom: 7,
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#b3dcef',
                'fill-opacity': {
                    stops: [
                        [3, 1],
                        [8, 0],
                    ],
                },
                'fill-translate': {
                    base: 1.2,
                    stops: [
                        [7, [0, 0]],
                        [16, [-1, -1]],
                    ],
                },
                'fill-translate-anchor': 'viewport',
            },
        },
        {
            id: 'fill earth water',
            type: 'fill',
            source: 'natural-earth',
            'source-layer': 'water',
            maxzoom: 7,
            filter: ['in', '$type', 'LineString', 'Point', 'Polygon'],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#b3dcef',
                'fill-opacity': {
                    stops: [
                        [3, 1],
                        [8, 0],
                    ],
                },
            },
        },
        {
            id: 'fill water',
            type: 'fill',
            source: 'iran',
            'source-layer': 'water',
            minzoom: 0,
            filter: ['all', ['!has', 'intermittent']],
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': '#b3dcef',
                'fill-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'fill earth urban',
            type: 'fill',
            source: 'natural-earth',
            'source-layer': 'urban',
            minzoom: 5,
            maxzoom: 9,
            layout: { visibility: 'visible' },
            paint: {
                'fill-color': 'hsla(72, 5%, 77%, 0.47)',
                'fill-opacity': {
                    stops: [
                        [5, 0],
                        [7, 1],
                        [9, 0],
                    ],
                },
                'fill-antialias': true,
                'fill-outline-color': 'hsla(0, 100%, 99%, 0)',
            },
        },
        {
            id: 'fill earth ice',
            type: 'fill',
            source: 'natural-earth',
            'source-layer': 'ice',
            maxzoom: 6,
            layout: { visibility: 'visible' },
            paint: { 'fill-color': 'hsla(215, 92%, 88%, 0.32)' },
        },
        {
            id: 'line footway park',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 14,
            filter: [
                'all',
                ['==', 'class', 'path'],
                ['!=', 'subclass', 'platform'],
                ['in', 'name', ''],
            ],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-width': {
                    stops: [
                        [13, 0.5],
                        [20, 5],
                    ],
                },
                'line-color': 'rgba(238, 238, 238, 1)',
            },
        },
        {
            id: 'line footway',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 14,
            filter: [
                'all',
                ['==', 'class', 'path'],
                ['!=', 'subclass', 'platform'],
            ],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-width': {
                    stops: [
                        [13, 0.1],
                        [20, 2],
                        [21, 3],
                    ],
                },
                'line-color': '#EEEEEE',
            },
        },
        {
            id: 'line alley',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 13,
            filter: [
                'all',
                ['==', 'service', 'alley'],
                ['==', 'class', 'service'],
            ],
            layout: { visibility: 'visible' },
            paint: {
                'line-width': {
                    stops: [
                        [13, 1],
                        [15, 2],
                    ],
                },
                'line-color': 'rgba(203, 212, 220, 1)',
            },
        },
        {
            id: 'line streets far',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 9,
            maxzoom: 17,
            filter: [
                'all',
                ['!=', 'class', 'minor'],
                ['!=', 'class', 'path'],
                ['!=', 'service', 'alley'],
                ['!=', 'class', 'service'],
                ['!=', 'class', 'trunk'],
                ['!=', 'class', 'raill1'],
                ['has', 'class'],
                ['!=', 'subclass', 'subway'],
                ['!=', 'surface', 'paved'],
            ],
            layout: { visibility: 'visible' },
            paint: {
                'line-color': 'rgba(203, 212, 220, 1)',
                'line-width': 2,
                'line-opacity': {
                    stops: [
                        [9, 0],
                        [9.5, 1],
                    ],
                },
            },
        },
        {
            id: 'line streets close',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 12,
            maxzoom: 24,
            filter: [
                'all',
                ['!=', 'class', 'trunk'],
                ['!=', 'service', 'alley'],
                ['!=', 'class', 'path'],
                ['!=', 'class', 'service'],
                ['!=', 'class', 'rail'],
                ['!=', 'access', 'private'],
                ['!=', 'subclass', 'subway'],
                ['!=', 'class', 'transit'],
                ['!=', 'class', 'rail'],
                ['has', 'class'],
                ['!=', 'subclass', 'unclassified'],
            ],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
                'line-miter-limit': 2,
            },
            paint: {
                'line-width': {
                    stops: [
                        [12, 0.5],
                        [15, 3],
                    ],
                },
                'line-color': 'rgba(203, 212, 220, 1)',
                'line-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                        [22, 1],
                    ],
                },
            },
        },
        {
            id: 'line streets paved',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 12,
            maxzoom: 24,
            filter: [
                'all',
                ['!=', 'class', 'trunk'],
                ['!=', 'service', 'alley'],
                ['!=', 'class', 'path'],
                ['!=', 'class', 'rail'],
                ['!=', 'access', 'private'],
                ['!=', 'subclass', 'subway'],
                ['!=', 'class', 'transit'],
                ['!=', 'class', 'rail'],
                ['has', 'class'],
                ['!=', 'subclass', 'unclassified'],
                ['==', 'surface', 'paved'],
                ['!=', 'subclass', 'residential'],
                ['!=', 'class', 'tertiary'],
            ],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
                'line-miter-limit': 2,
            },
            paint: {
                'line-width': {
                    stops: [
                        [12, 0.5],
                        [15, 3],
                    ],
                },
                'line-color': 'rgba(220, 220, 220, 1)',
                'line-dasharray': [0.2, 4],
                'line-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                        [13, 1],
                    ],
                },
            },
        },
        {
            id: 'line aeroway',
            type: 'line',
            source: 'iran',
            'source-layer': 'aeroway',
            minzoom: 12,
            filter: [
                'all',
                ['!=', 'class', 'apron'],
                ['!=', 'access', 'privatee'],
            ],
            layout: { visibility: 'visible' },
            paint: {
                'line-color': 'rgba(255, 255, 255, 1)',
                'line-width': {
                    stops: [
                        [1, 12],
                        [12, 1],
                    ],
                },
            },
        },
        {
            id: 'line aeroway_private',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 13,
            filter: [
                'all',
                ['==', 'access', 'private'],
                ['!=', 'subclass', 'residential'],
            ],
            layout: { visibility: 'visible' },
            paint: { 'line-color': 'rgba(236, 235, 235, 1)', 'line-width': 2 },
        },
        {
            id: 'line airplan',
            type: 'line',
            source: 'iran',
            'source-layer': 'aeroway',
            minzoom: 9,
            filter: ['all', ['==', 'class', 'runway']],
            layout: { 'line-cap': 'round' },
            paint: {
                'line-width': {
                    stops: [
                        [12.5, 5],
                        [14, 20],
                    ],
                    base: 5,
                },
                'line-color': 'rgba(255, 255, 255, 1)',
                'line-opacity': {
                    stops: [
                        [11, 0],
                        [11.5, 1],
                    ],
                },
            },
        },
        {
            id: 'line river',
            type: 'line',
            source: 'iran',
            'source-layer': 'waterway',
            minzoom: 11,
            paint: {
                'line-width': 3,
                'line-color': '#abeffc',
                'line-opacity': {
                    stops: [
                        [12, 0],
                        [12.5, 1],
                        [22, 1],
                    ],
                },
            },
        },
        {
            id: 'line largeline primary',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 0,
            maxzoom: 22,
            filter: ['all', ['==', 'class', 'primary']],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#8aa4c0',
                'line-width': {
                    stops: [
                        [6, 0],
                        [9, 3],
                        [10, 2],
                        [15, 5],
                        [17, 6],
                        [24, 8],
                    ],
                    base: 1,
                },
                'line-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'line largeline trunk',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            minzoom: 0,
            maxzoom: 22,
            filter: ['all', ['==', 'class', 'trunk']],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#8aa4c0',
                'line-width': {
                    stops: [
                        [6, 0],
                        [9, 3],
                        [10, 2],
                        [15, 5],
                        [17, 6],
                        [24, 8],
                    ],
                    base: 1,
                },
                'line-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'line motorway',
            type: 'line',
            source: 'iran',
            'source-layer': 'transportation',
            maxzoom: 9,
            filter: ['all', ['==', 'class', 'motorway']],
            layout: {
                visibility: 'visible',
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#8aa4c0',
                'line-width': {
                    stops: [
                        [6, 0],
                        [9, 2],
                        [11, 3],
                    ],
                },
                'line-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'line earth river',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'river',
            layout: { 'line-cap': 'round' },
            paint: {
                'line-color': '#abeffc',
                'line-width': {
                    base: 1.2,
                    stops: [
                        [11, 2],
                        [20, 6],
                    ],
                },
                'line-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                    ],
                },
            },
        },
        {
            id: 'line earth road_motorway_casing',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'road',
            minzoom: 6,
            maxzoom: 8,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
                visibility: 'visible',
            },
            paint: {
                'line-color': '#8aa4c0',
                'line-width': {
                    base: 1.2,
                    stops: [
                        [5, 0.4],
                        [6, 0.6],
                        [7, 1.5],
                        [20, 22],
                    ],
                },
                'line-opacity': {
                    stops: [
                        [6, 0],
                        [7, 1],
                        [8, 0],
                    ],
                },
            },
        },
        {
            id: 'line earth road_motorway',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'road',
            minzoom: 5,
            maxzoom: 7,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
                visibility: 'none',
            },
            paint: {
                'line-color': '#fc8',
                'line-width': {
                    base: 1.2,
                    stops: [
                        [6.5, 0],
                        [7, 0.5],
                        [20, 18],
                    ],
                },
                'line-opacity': {
                    stops: [
                        [6, 1],
                        [8, 0],
                    ],
                },
            },
        },
        {
            id: 'line earth road_major_rail',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'railroad',
            maxzoom: 6,
            layout: { visibility: 'none' },
            paint: {
                'line-color': 'hsl(0, 68%, 40%)',
                'line-width': {
                    base: 1.2,
                    stops: [
                        [6.5, 0],
                        [7, 0.5],
                        [20, 18],
                    ],
                },
            },
        },
        {
            id: 'line earth road_major_rail_hatching',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'railroad',
            maxzoom: 6,
            layout: { visibility: 'none' },
            paint: {
                'line-color': 'hsl(0, 68%, 40%)',
                'line-dasharray': [0.2, 8],
                'line-width': {
                    base: 1.2,
                    stops: [
                        [5, 0.6],
                        [6, 0.8],
                        [7, 1.8],
                        [20, 22],
                    ],
                },
            },
        },
        {
            id: 'line earth admin_level_1',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'admin',
            maxzoom: 6,
            filter: ['==', 'admin_level', 1],
            layout: { 'line-join': 'round', visibility: 'none' },
            paint: {
                'line-color': '#9e9cab',
                'line-dasharray': [3, 1, 1, 1],
                'line-width': {
                    base: 1,
                    stops: [
                        [2, 0.4],
                        [5, 1],
                        [12, 3],
                    ],
                },
            },
        },
        {
            id: 'line earth admin_level_0',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'admin',
            maxzoom: 20,
            filter: [
                'all',
                [
                    'all',
                    ['!=', ['get', 'class'], 'dispute'],
                    ['==', ['get', 'admin_level'], 0],
                ],
                [
                    'match',
                    ['geometry-type'],
                    ['LineString', 'Point', 'Polygon'],
                    true,
                    false,
                ],
            ],
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                visibility: 'visible',
            },
            paint: {
                'line-color': '#9e9cab',
                'line-width': {
                    base: 1,
                    stops: [
                        [0, 0.4],
                        [4, 1.4],
                        [5, 2],
                        [12, 8],
                    ],
                },
            },
        },
        {
            id: 'line earth admin_level_0_disputed',
            type: 'line',
            source: 'natural-earth',
            'source-layer': 'admin',
            maxzoom: 6,
            filter: [
                'all',
                ['==', 'admin_level', 0],
                ['==', 'class', 'dispute'],
            ],
            layout: { 'line-cap': 'round', visibility: 'none' },
            paint: {
                'line-color': '#9e9cab',
                'line-dasharray': [2, 2],
                'line-width': {
                    base: 1,
                    stops: [
                        [0, 0.4],
                        [4, 1.4],
                        [5, 2],
                        [12, 8],
                    ],
                },
            },
        },
        {
            id: 'text park',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 13,
            filter: ['all', ['==', 'class', 'park'], ['has', 'name']],
            layout: {
                'text-size': {
                    stops: [
                        [13, 11],
                        [15, 11],
                        [18, 16],
                    ],
                },
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-optional': true,
                'icon-text-fit': 'none',
                'symbol-placement': 'point',
                'icon-rotation-alignment': 'auto',
                'icon-anchor': 'bottom',
                'icon-offset': [0, -100],
                'icon-pitch-alignment': 'auto',
                'icon-size': 0.07,
                'icon-keep-upright': false,
                'icon-image': 'park',
            },
            paint: {
                'icon-color': 'rgba(255, 255, 255, 1)',
                'icon-opacity': 1,
                'icon-halo-color': 'rgba(255, 255, 255, 1)',
                'text-color': 'rgba(13, 139, 37, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text alley',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'transportation_name',
            minzoom: 13,
            filter: ['any', ['==', 'class', 'minor']],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'symbol-z-order': 'viewport-y',
                visibility: 'visible',
                'text-size': 12,
                'symbol-placement': 'line',
                'text-pitch-alignment': 'auto',
                'text-justify': 'center',
                'symbol-avoid-edges': false,
            },
            paint: {
                'text-opacity': {
                    stops: [
                        [13, 0.2],
                        [15, 1],
                    ],
                },
                'text-color': 'rgba(0, 0, 0, 1)',
            },
        },
        {
            id: 'text street',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'transportation_name',
            minzoom: 13,
            filter: ['all', ['in', 'class', 'service', 'tertiary', 'primary']],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'symbol-z-order': 'viewport-y',
                visibility: 'visible',
                'text-size': {
                    stops: [
                        [13, 12],
                        [20, 20],
                    ],
                },
                'symbol-placement': 'line',
                'text-pitch-alignment': 'auto',
                'text-justify': 'center',
                'symbol-avoid-edges': false,
            },
            paint: {
                'text-opacity': {
                    stops: [
                        [13, 0.5],
                        [17, 1],
                    ],
                },
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-width': 0.75,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text bus',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 16,
            filter: [
                'any',
                ['==', 'class', 'bus'],
                ['==', 'subclass', 'bus_stop'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                'text-size': 12,
                visibility: 'none',
                'symbol-placement': 'point',
            },
            paint: {
                'text-opacity': 0.6,
                'text-halo-width': 2,
                'text-halo-color': 'rgba(0, 0, 0, 1)',
                'text-color': 'rgba(255, 255, 255, 1)',
            },
        },
        {
            id: 'text hospital',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 14,
            filter: [
                'all',
                ['==', 'class', 'hospital'],
                ['!=', 'subclass', 'clinic'],
                ['!=', 'subclass', 'nursing_home'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                'text-size': 12,
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-size': 1,
                'icon-rotation-alignment': 'viewport',
                'icon-optional': true,
                'icon-anchor': 'bottom',
                'icon-offset': [0, -200],
                'icon-pitch-alignment': 'auto',
            },
            paint: {
                'text-color': 'rgba(209, 112, 112, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text nursing_home',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 16,
            filter: [
                'all',
                ['==', 'class', 'hospital'],
                ['==', 'subclass', 'nursing_home'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                'text-size': 12,
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-size': 1,
                'icon-rotation-alignment': 'viewport',
                'icon-optional': true,
                'icon-anchor': 'bottom',
                'icon-offset': [0, -200],
                'icon-pitch-alignment': 'auto',
            },
            paint: {
                'text-color': 'rgba(209, 112, 112, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text clinic',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 16,
            filter: [
                'all',
                ['==', 'class', 'hospital'],
                ['==', 'subclass', 'clinic'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                'text-size': 12,
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-size': 1,
                'icon-rotation-alignment': 'viewport',
                'icon-optional': true,
                'icon-anchor': 'bottom',
                'icon-offset': [0, -200],
                'icon-pitch-alignment': 'auto',
            },
            paint: {
                'text-color': 'rgba(209, 112, 112, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text school',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 14.5,
            filter: [
                'any',
                ['==', 'class', 'school'],
                ['==', 'class', 'college'],
                ['==', 'class', 'university'],
            ],
            layout: {
                'text-field': '{name}',
                'text-size': {
                    stops: [
                        [14, 8],
                        [20, 16],
                    ],
                },
                visibility: 'visible',
                'text-font': ['Vazir Regular'],
                'symbol-placement': 'point',
                'icon-image': 'college',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-optional': false,
                'icon-size': 0.055,
                'icon-text-fit': 'none',
                'icon-keep-upright': false,
                'icon-anchor': 'bottom',
                'icon-offset': [0, -250],
                'icon-pitch-alignment': 'auto',
                'text-keep-upright': false,
            },
            paint: { 'text-color': 'rgba(0, 0, 0, 1)' },
        },
        {
            id: 'text water',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'water_name',
            minzoom: 15,
            filter: ['all', ['==', 'class', 'lake'], ['has', 'name']],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                visibility: 'none',
                'text-size': 18,
            },
            paint: {
                'text-opacity': 0.7,
                'text-halo-width': 0,
                'text-halo-color': 'rgba(138, 138, 138, 1)',
            },
        },
        {
            id: 'text square',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'transportation_name',
            minzoom: 13.5,
            filter: ['all', ['==', 'class', 'secondary'], ['has', 'name']],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-size': 14,
                'icon-allow-overlap': false,
                'icon-optional': false,
                'text-field': '{name}',
                visibility: 'none',
                'symbol-placement': 'line',
            },
            paint: {
                'text-opacity': 0.8,
                'text-halo-width': 2,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
            },
        },
        {
            id: 'text subway',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 10,
            filter: [
                'any',
                ['==', 'class', 'railway'],
                ['==', 'subclass', 'subway'],
            ],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                visibility: 'none',
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-opacity': 0.5,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
            },
        },
        {
            id: 'text largeline',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'transportation_name',
            minzoom: 10,
            filter: ['all', ['==', 'class', 'trunk']],
            layout: {
                'text-size': {
                    stops: [
                        [10, 15],
                        [20, 18],
                    ],
                },
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                visibility: 'visible',
                'symbol-placement': 'line',
                'symbol-avoid-edges': true,
            },
            paint: {
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-opacity': {
                    stops: [
                        [10, 0.5],
                        [11, 1],
                        [15, 1],
                    ],
                },
            },
        },
        {
            id: 'text neighbourhood',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            minzoom: 10,
            maxzoom: 14,
            filter: ['all', ['==', 'class', 'neighbourhood']],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                visibility: 'visible',
                'text-size': {
                    stops: [
                        [10, 15],
                        [18, 20],
                    ],
                },
                'symbol-avoid-edges': false,
                'symbol-placement': 'point',
                'symbol-z-order': 'auto',
            },
            paint: {
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-opacity': {
                    stops: [
                        [12, 1],
                        [15, 0.5],
                    ],
                },
                'text-halo-width': 1,
            },
        },
        {
            id: 'text village',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            minzoom: 12,
            maxzoom: 15,
            filter: [
                'all',
                ['!=', 'class', 'borough'],
                ['!=', 'class', 'city'],
                ['!=', 'class', 'quarter'],
                ['==', 'class', 'village'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                visibility: 'visible',
                'text-size': 20,
                'symbol-avoid-edges': false,
                'symbol-placement': 'point',
                'symbol-z-order': 'auto',
            },
            paint: {
                'text-opacity': 1,
                'text-halo-width': 1,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-color': 'rgba(0, 0, 0, 1)',
            },
        },
        {
            id: 'text area',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            minzoom: 9,
            maxzoom: 12.5,
            filter: [
                'all',
                ['!=', 'class', 'borough'],
                ['!=', 'class', 'city'],
                ['!=', 'class', 'quarter'],
                ['!=', 'class', 'village'],
            ],
            layout: {
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                visibility: 'visible',
                'text-size': 20,
                'symbol-avoid-edges': false,
                'symbol-placement': 'point',
                'symbol-z-order': 'auto',
            },
            paint: {
                'text-opacity': 1,
                'text-halo-width': 1,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-color': 'rgba(0, 0, 0, 1)',
            },
        },
        {
            id: 'text city',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            minzoom: 0,
            maxzoom: 11,
            filter: ['all', ['==', 'class', 'city'], ['>=', 'rank', 8]],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-size': {
                    stops: [
                        [5, 16],
                        [11, 12],
                        [12, 24],
                    ],
                },
                visibility: 'visible',
                'text-field': '{name}',
            },
            paint: {
                'text-opacity': 1,
                'icon-color': 'rgba(255, 255, 255, 1)',
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text city big',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            minzoom: 0,
            maxzoom: 11.5,
            filter: ['all', ['==', 'class', 'city'], ['has', 'capital']],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-size': {
                    stops: [
                        [4, 10],
                        [11, 24],
                    ],
                },
                visibility: 'visible',
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text state',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            maxzoom: 8,
            filter: ['any', ['has', 'capital']],
            layout: {
                visibility: 'visible',
                'text-field': '{name}',
                'text-font': ['Vazir Regular'],
                'text-size': {
                    base: 1.2,
                    stops: [
                        [4, 20],
                        [11, 24],
                    ],
                },
                'text-max-width': 2,
                'text-transform': 'uppercase',
                'text-letter-spacing': 0.15,
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-color': 'rgba(255, 255, 255, 0.8)',
                'text-halo-blur': 1,
                'text-halo-width': 1,
            },
        },
        {
            id: 'text mountain',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'mountain_peak',
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                visibility: 'visible',
            },
            paint: { 'text-color': 'rgba(0, 0, 0, 1)' },
        },
        {
            id: 'text earth marine_label_sm',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'marine_label',
            minzoom: 3,
            maxzoom: 10,
            filter: ['all', ['==', '$type', 'Point'], ['>=', 'scalerank', 4]],
            layout: {
                'text-line-height': 1.6,
                'text-size': {
                    stops: [
                        [3, 12],
                        [6, 16],
                    ],
                },
                'text-font': ['Vazir Regular'],
                'symbol-placement': 'point',
                'text-offset': [0, 0],
                'text-anchor': 'center',
                'text-field': '{name}',
                'text-letter-spacing': 0.2,
                'text-max-width': 5,
            },
            paint: {
                'text-color': '#b3dcef',
                'text-halo-color': 'rgba(255,255,255,0.7)',
                'text-halo-width': 0.75,
                'text-halo-blur': 0.75,
            },
        },
        {
            id: 'text earth marine_label_md',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'marine_label',
            minzoom: 2,
            maxzoom: 8,
            filter: ['all', ['<=', 'scalerank', 3], ['==', '$type', 'Point']],
            layout: {
                'text-line-height': 1.6,
                'text-size': {
                    stops: [
                        [2, 14],
                        [5, 20],
                    ],
                },
                'text-font': ['Vazir Regular'],
                'symbol-placement': 'point',
                'text-offset': [0, 0],
                'text-anchor': 'center',
                'text-field': '{name}',
                'text-letter-spacing': 0.2,
                'text-max-width': 5,
            },
            paint: {
                'text-color': 'rgba(255, 255, 255, 1)',
                'text-halo-color': '#b3dcef',
                'text-halo-width': 0.75,
                'text-halo-blur': 0.75,
            },
        },
        {
            id: 'text earth marine_label_lg',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'marine_label',
            filter: ['all', ['==', '$type', 'Point'], ['==', 'scalerank', 0]],
            layout: {
                'text-line-height': 1.6,
                'text-size': {
                    stops: [
                        [1, 14],
                        [4, 30],
                    ],
                },
                'text-font': ['Vazir Regular'],
                'symbol-placement': 'point',
                'text-offset': [0, 0],
                'text-anchor': 'center',
                'text-field': '{name}',
                'text-letter-spacing': 0.2,
                'text-max-width': 5,
            },
            paint: {
                'text-color': '#b3dcef',
                'text-halo-color': 'rgba(255,255,255,0.7)',
                'text-halo-width': 0.75,
                'text-halo-blur': 0.75,
            },
        },
        {
            id: 'text earth place_label_city',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'place_label',
            maxzoom: 6,
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-max-width': 8,
                'text-size': {
                    base: 1.2,
                    stops: [
                        [7, 12],
                        [11, 24],
                    ],
                },
                visibility: 'none',
            },
            paint: {
                'text-color': '#333',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 1.2,
            },
        },
        {
            id: 'text earth country_label_sm',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'country_label',
            maxzoom: 6,
            filter: ['==', 'is_tiny', 1],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-max-width': 6.25,
                'text-transform': 'uppercase',
                'text-size': {
                    base: 0.9,
                    stops: [
                        [5, 14],
                        [9, 22],
                    ],
                },
            },
            paint: {
                'text-color': '#334',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 2,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text earth country_label_md',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'country_label',
            maxzoom: 6,
            filter: ['==', 'is_tiny', 0],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-max-width': 6.25,
                'text-transform': 'uppercase',
                'text-size': {
                    stops: [
                        [3, 10],
                        [8, 24],
                    ],
                },
                visibility: 'none',
            },
            paint: {
                'text-color': '#334',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 2,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text earth country_label_lg-copy',
            type: 'symbol',
            source: 'natural-earth',
            'source-layer': 'country_label',
            maxzoom: 5,
            filter: [
                'all',
                ['<=', 'labelrank', 5],
                ['==', 'is_tiny', 0],
                ['==', 'scalerank', 0],
            ],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-max-width': 6.25,
                'text-transform': 'uppercase',
                'text-size': {
                    stops: [
                        [1, 10],
                        [7, 24],
                    ],
                },
            },
            paint: {
                'text-color': '#334',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 2,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'text country_label_lg',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'place',
            maxzoom: 5,
            filter: ['all', ['==', 'class', 'country']],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'text-max-width': 6.25,
                'text-transform': 'uppercase',
                'text-size': {
                    stops: [
                        [1, 10],
                        [7, 24],
                    ],
                },
            },
            paint: {
                'text-color': '#334',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 2,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'poi',
            type: 'symbol',
            source: 'iran',
            'source-layer': 'poi',
            minzoom: 0,
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                visibility: 'none',
                'text-size': 16,
            },
        },
        {
            id: 'eatery blur',
            type: 'symbol',
            source: 'gooje',
            'source-layer': 'eatery',
            filter: [
                'all',
                ['==', 'is_private', false],
                ['!=', 'is_for_guest', true],
            ],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'icon-image': [
                    'match',
                    ['get', 'category'],
                    1,
                    'cafe',
                    2,
                    'restaurant',
                    3,
                    'fastfood',
                    4,
                    'seafood',
                    5,
                    'icecream',
                    6,
                    'dizi',
                    7,
                    'ash',
                    8,
                    'kababi',
                    9,
                    'kalle_pache',
                    10,
                    'liver',
                    'default',
                ],
                'text-allow-overlap': false,
                'icon-ignore-placement': false,
                'icon-anchor': 'bottom',
                'text-anchor': 'top',
                'icon-offset': [0, 0],
                visibility: 'visible',
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-width': 1,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-blur': 0,
                'text-translate': [0, 0],
                'icon-opacity': 0.45,
                'text-opacity': 0.45,
            },
        },
        {
            id: 'eatery guest',
            type: 'symbol',
            source: 'gooje',
            'source-layer': 'eatery',
            filter: [
                'all',
                ['==', 'is_private', false],
                ['==', 'is_for_guest', true],
            ],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'icon-image': [
                    'match',
                    ['get', 'category'],
                    1,
                    'cafe',
                    2,
                    'restaurant',
                    3,
                    'fastfood',
                    4,
                    'seafood',
                    5,
                    'icecream',
                    6,
                    'dizi',
                    7,
                    'ash',
                    8,
                    'kababi',
                    9,
                    'kalle_pache',
                    10,
                    'liver',
                    'default',
                ],
                'text-allow-overlap': false,
                'icon-ignore-placement': false,
                'icon-anchor': 'bottom',
                'text-anchor': 'top',
                'icon-offset': [0, 0],
                visibility: 'visible',
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-width': 1,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-blur': 0,
                'text-translate': [0, 0],
            },
        },
        {
            id: 'eatery normal',
            type: 'symbol',
            source: 'gooje',
            'source-layer': 'eatery',
            filter: ['all', ['==', 'is_private', false]],
            layout: {
                'text-font': ['Vazir Regular'],
                'text-field': '{name}',
                'icon-image': [
                    'match',
                    ['get', 'category'],
                    1,
                    'cafe',
                    2,
                    'restaurant',
                    3,
                    'fastfood',
                    4,
                    'seafood',
                    5,
                    'icecream',
                    6,
                    'dizi',
                    7,
                    'ash',
                    8,
                    'kababi',
                    9,
                    'kalle_pache',
                    10,
                    'liver',
                    'default',
                ],
                'text-allow-overlap': false,
                'icon-ignore-placement': false,
                'icon-anchor': 'bottom',
                'text-anchor': 'top',
                'icon-offset': [0, 0],
                visibility: 'visible',
            },
            paint: {
                'text-color': 'rgba(0, 0, 0, 1)',
                'text-halo-width': 1,
                'text-halo-color': 'rgba(255, 255, 255, 1)',
                'text-halo-blur': 0,
                'text-translate': [0, 0],
            },
        },
    ],
    id: 'gooje-map-light',
}
