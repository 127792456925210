import { Component, createEffect, onCleanup } from 'solid-js'

import './style/plzlogin.scss'

import { Check2Icon, CloseIcon } from '!/icons'
import { setSelf } from '!/store'
import { createStore } from 'solid-js/store'

type SuggestProps = {
    show: boolean

    onReject: () => void

    class?: string
}

const DEFAULT_SUGGEST: SuggestProps = {
    show: false,

    onReject: () => {},

    class: '',
}
export const [suggestLogin, setSuggestLogin] = createStore({
    ...DEFAULT_SUGGEST,
})

export const PlzLogin: Component = props => {
    let ref: HTMLElement

    let interval: ReturnType<typeof setTimeout>
    let interval2: ReturnType<typeof setTimeout>

    // createEffect(() => {
    //     if (!ref || !popup.show) return

    // })

    createEffect(() => {
        if (!suggestLogin.show) return

        window.addEventListener('popstate', e => {
            e.preventDefault()
            close()
        })

        onCleanup(() => {
            window.removeEventListener('popstate', e => {
                e.preventDefault()
                close()
            })
        })
    })

    onCleanup(() => {
        clearTimeout(interval)
        clearTimeout(interval2)
    })

    const close = () => {
        suggestLogin.onReject()
        setSuggestLogin({ ...DEFAULT_SUGGEST })
    }

    let startX: number

    const onTouchStart = e => {
        e.stopPropagation()

        startX = e.touches[0].clientX

        e.currentTarget.style.transition = 'none'
    }

    const onTouchMove = e => {
        e.stopPropagation()

        const deltaX = e.touches[0].clientX - startX

        e.currentTarget.style.transform = `translateX(${deltaX}px)`
        e.currentTarget.style.opacity = `${Math.max(0.01, 1 - Math.abs(deltaX) / 500)}`
    }

    const onTouchEnd = e => {
        e.stopPropagation()

        const deltaX = e.changedTouches[0].clientX - startX

        if (Math.abs(deltaX) > 100) {
            e.currentTarget.style.transition = '0.3s ease'
            e.currentTarget.style.opacity = '0'
            close()

            interval2 = setTimeout(() => {
                ref.removeAttribute('style')
            }, 100)
        } else {
            e.currentTarget.style.transition = ''
            e.currentTarget.style.transform = ''
            e.currentTarget.style.opacity = ''
        }
    }

    return (
        <div
            class='suggest-login'
            onclick={() => {
                close()
            }}
            classList={{ show: suggestLogin.show }}
        >
            <form
                ref={e => (ref = e)}
                class='suggest-wrapper'
                onclick={e => {
                    e.stopPropagation()
                }}
                onsubmit={e => {
                    e.preventDefault()

                    setSuggestLogin({ ...DEFAULT_SUGGEST })
                    setSelf({ login: true })
                }}
                onreset={e => {
                    e.preventDefault()

                    close()
                }}
                ontouchstart={onTouchStart}
                ontouchmove={onTouchMove}
                ontouchend={onTouchEnd}
            >
                <button type='reset' class='close icon'>
                    <CloseIcon />
                </button>
                <div class='gooje-icon'>
                    <img src='/public/imgs/gooje/happy.png' alt='' />
                </div>

                <div class='suggest-titles'>
                    <h1 class='suggest-head title'>از %100 گوجه استفاده کن!</h1>
                    <p class='description'>
                        در چند ثانیه ثبت نام کنید و از تمام قابلیت های گوجه
                        استفاده کنید.
                    </p>
                </div>

                <div class='options title_small'>
                    <div class='option'>
                        <Check2Icon />
                        دسترسی به تمام غداخوری ها
                    </div>
                    <div class='option'>
                        <Check2Icon />
                        جستجو اختصاصی برای شما
                    </div>
                    <div class='option'>
                        <Check2Icon />
                        ذخیره غذاخوری ها
                    </div>
                    <div class='option'>
                        <Check2Icon />
                        دسترسی به تخفیف‌ها و پیشنهادات ویژه
                    </div>
                </div>

                <button
                    class='cta title_small'
                    type='submit'
                    ontouchmove={e => {
                        e.stopPropagation()
                    }}
                    ontouchstart={e => {
                        e.stopPropagation()
                    }}
                    onTouchEnd={e => {
                        e.stopPropagation()
                    }}
                >
                    بزن بریم!
                </button>
            </form>
        </div>
    )
}
