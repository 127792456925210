import {
    Component,
    createSignal,
    createUniqueId,
    For,
    JSX,
    onCleanup,
    Show,
} from 'solid-js'
import './style/alert.scss'

import { Check2Icon, CloseIcon, WarnnigIcon } from '!/icons'
import { createStore, produce } from 'solid-js/store'
import { SeedError } from '!/api'
import { SEED_ERROR_MESSAGE_FA } from '!/api/errors'

type AlertModel = {
    type: 'info' | 'error' | 'success'
    id: string
    subject: string
    content?: string
    timeout: number
}

type AlertState = {
    alerts: AlertModel[]
}

export const [alert_state, setAlertState] = createStore<AlertState>({
    alerts: [],
})

export function addAlert(props: Omit<AlertModel, 'id'>) {
    if (alert_state.alerts.length >= 2) return

    const id = createUniqueId()
    setAlertState(
        produce(s => {
            s.alerts.unshift({ ...props, id })
        })
    )
}

export function delAlert(id: string) {
    const index = alert_state.alerts.findIndex(a => a.id === id)

    if (index === -1) return

    setAlertState(
        produce(s => {
            s.alerts.splice(index, 1)
        })
    )
}

const ALERT_ICON: {
    [x in AlertModel['type']]: () => JSX.Element
} = {
    info: () => <WarnnigIcon />,
    error: () => <CloseIcon />,
    success: () => <Check2Icon />,
}

export const Alerts: Component = () => {
    return (
        <div class='alerts' classList={{ show: alert_state.alerts.length > 0 }}>
            <For each={alert_state.alerts}>
                {a => (
                    <Alert
                        a={a}
                        onDel={() => {
                            delAlert(a.id)
                        }}
                    />
                )}
            </For>
        </div>
    )
}

interface alertProps {
    a: AlertModel
    onDel(): void
}
const Alert: Component<alertProps> = P => {
    const [hide, setHide] = createSignal(false)

    let interval: ReturnType<typeof setTimeout>
    let interval2: ReturnType<typeof setTimeout>

    onCleanup(() => {
        clearTimeout(interval)
        clearTimeout(interval2)
    })
    let startY = 0

    const onTouchStart = e => {
        startY = e.touches[0].clientY

        e.currentTarget.style.transition = 'none'
    }

    const onTouchMove = e => {
        const deltaY = e.touches[0].clientY - startY
        e.currentTarget.style.transform = `translateY(${deltaY}px)`
        e.currentTarget.style.opacity = `${1 - Math.abs(deltaY) / 100}`
    }

    const onTouchEnd = e => {
        const deltaY = e.changedTouches[0].clientY - startY

        if (Math.abs(deltaY) > 80) {
            interval2 = setTimeout(() => {
                P.onDel()
            }, 500)
        } else {
            e.currentTarget.style.transition =
                'transform 0.2s ease, opacity 0.2s ease'
            e.currentTarget.style.transform = 'translateY(0)'
            e.currentTarget.style.opacity = '1'
        }
    }

    return (
        <div
            class={`alert-container ${P.a.type}`}
            ontouchstart={onTouchStart}
            ontouchmove={onTouchMove}
            ontouchend={onTouchEnd}
            onclick={() => {
                setHide(true)

                interval = setTimeout(() => {
                    P.onDel()
                }, 500)
            }}
        >
            <div class={`alert`} classList={{ hide: hide() }}>
                <div class='head'>
                    <div class='icon'>{ALERT_ICON[P.a.type]()}</div>
                    <div class='content description'>
                        <h3>{P.a.subject}</h3>
                    </div>
                </div>
                <Show when={P.a.content}>
                    <div class='body description'>
                        <p>{P.a.content}</p>
                    </div>
                </Show>

                <div
                    class='line'
                    onanimationend={() => {
                        if (hide()) return

                        setHide(true)

                        interval = setTimeout(() => {
                            P.onDel()
                        }, 500)
                    }}
                    style={{ 'animation-duration': `${P.a.timeout}s` }}
                ></div>
            </div>
        </div>
    )
}

export function alert_code(code: SeedError) {
    let msg = SEED_ERROR_MESSAGE_FA[code]
    addAlert({
        type: 'error',
        timeout: 10,
        subject: msg.subject,
        content: msg.content || undefined,
    })
}
