import { DishKind, EateryCategory, EateryTheme, ReviewState } from 'api'
import { JSX } from 'solid-js'
import {
    Check2Icon,
    CoffeeIcon,
    IcecreamIcon,
    KebabIcon,
    QuestionIcon,
    CloseFillIcon,
    CloseIcon,
    EyeIcon,
    FriesIcon,
    TeaIcon,
    JuiceIcon,
    SoupIcon,
    PastaIcon,
    SandwichIcon,
    FoodIcon,
    ChickenIcon,
    RiceIcon,
    SteakIcon,
    SaladIcon,
    SushiIcon,
    BurgerRef,
    DessertRef,
    FishIcon,
    CoffeeRef,
    RestaurantRef,
    PizzaRef,
    ChopstickRef,
    IcecreamRef,
    DrinkRef,
} from 'icons'

export type EateryThemeDpy = {
    [k in EateryTheme]: {
        image: string
        icon: () => JSX.Element
    }
}

export const EATERY_THEME_DPY: EateryThemeDpy = {
    unknown: { image: '', icon: QuestionIcon },
    luxury: { image: '/public/imgs/themes/luxury.webp', icon: () => '' },
    romantic: { image: '/public/imgs/themes/romantic.webp', icon: () => '' },
    friendly: { image: '/public/imgs/themes/friends.webp', icon: () => '' },
    family: { image: '/public/imgs/themes/family.webp', icon: () => '' },
    modern: { image: '/public/imgs/themes/modern.webp', icon: () => '' },
    business: { image: '/public/imgs/themes/bussiness.webp', icon: () => '' },
    sports: { image: '/public/imgs/themes/sport.webp', icon: () => '' },
    mafia: { image: '/public/imgs/themes/mafia.webp', icon: () => '' },
    traditional: { image: '/public/imgs/themes/culture.webp', icon: () => '' },
    nature: { image: '/public/imgs/themes/nature.webp', icon: () => '' },
}

export type ReviewStateDpy = {
    [k in ReviewState]: {
        icon: () => JSX.Element
    }
}

export const REVIEW_STATE_DPY: ReviewStateDpy = {
    admin_censored: { icon: CloseIcon },
    admin_verified: { icon: Check2Icon },
    bot_censored: { icon: CloseFillIcon },
    bot_verified: { icon: Check2Icon },
    default: { icon: EyeIcon },
    staff_censored: { icon: Check2Icon },
    staff_verified: { icon: CloseFillIcon },
    user_deleted: { icon: () => null },
}

export type EateryCategoryDpy = {
    [k in EateryCategory]: {
        image: string
        icon: () => JSX.Element
        mapIcon: string
    }
}

export const EATERY_CATEGORY_DPY: EateryCategoryDpy = {
    unknown: { image: '', icon: QuestionIcon, mapIcon: '' },
    cafe: {
        image: '/public/imgs/filters/cafe.webp',
        icon: CoffeeRef,
        mapIcon: '/public/svgs/coffee.svg',
    },
    restaurant: {
        image: '/public/imgs/filters/restaurant.webp',
        icon: RestaurantRef,
        mapIcon: '/public/svgs/restaurant.svg',
    },
    fast_food: {
        image: '/public/imgs/filters/burger.webp',
        icon: PizzaRef,
        mapIcon: '/public/svgs/fastfood.svg',
    },
    seafood: {
        image: '/public/imgs/filters/sea.webp',
        icon: ChopstickRef,
        mapIcon: '/public/svgs/sea.svg',
    },
    ice_cream: {
        image: '/public/imgs/filters/icecream.webp',
        icon: IcecreamRef,
        mapIcon: '/public/svgs/icecream.svg',
    },
    dizi: {
        image: '/public/imgs/filters/dizi.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/dizi.svg',
    },
    ash: {
        image: '/public/imgs/filters/aush.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/aush.svg',
    },
    kababi: {
        image: '/public/imgs/filters/kebab.webp',
        icon: KebabIcon,
        mapIcon: '/public/svgs/kebab.svg',
    },
    kalle_pache: {
        image: '/public/imgs/filters/khash.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/khash.svg',
    },
    liver: {
        image: '/public/imgs/filters/liver.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/liver.svg',
    },
}

export type DishKindDpy = {
    [k in DishKind]: {
        image: string
        icon: () => JSX.Element
    }
}

export const DISH_KIND_DPY: DishKindDpy = {
    unknown: { image: '', icon: QuestionIcon },
    food: { image: '', icon: FoodIcon },
    pizza: { image: '', icon: () => '' },
    burger: { image: '', icon: BurgerRef },
    dessert: { image: '', icon: DessertRef },
    pasta: { image: '', icon: PastaIcon },
    icecream: { image: '', icon: IcecreamIcon },
    seafood: { image: '', icon: SushiIcon },
    soup: { image: '', icon: SoupIcon },
    kabab: { image: '', icon: KebabIcon },
    polo: { image: '', icon: RiceIcon },
    chicken: { image: '', icon: ChickenIcon },
    steak: { image: '', icon: SteakIcon },
    sandwich: { image: '', icon: SandwichIcon },
    salad: { image: '', icon: SaladIcon },
    fried: { image: '', icon: FriesIcon },
    fish: { image: '', icon: FishIcon },
    drink: { image: '', icon: DrinkRef },
    tea: { image: '', icon: TeaIcon },
    sushi: { image: '', icon: SushiIcon },
    ash: { image: '', icon: QuestionIcon },
    coffee: { image: '', icon: CoffeeIcon },
    soda: { image: '', icon: () => '' },
    mojito: { image: '', icon: () => '' },
    lemonade: { image: '', icon: () => '' },
    juice: { image: '', icon: JuiceIcon },
    chocolate: { image: '', icon: () => '' },
    milk: { image: '', icon: () => '' },
    smoothie: { image: '', icon: () => '' },
    meat: { image: '', icon: () => '' },
    noodle: { image: '', icon: () => '' },
    sides: { image: '', icon: () => '' },
}
