import {
    api_eateries_get,
    api_guest_eateries_get,
    EateryGuestInfo,
    EateryInfo,
    Gene,
} from '!/api'
import { createStore } from 'solid-js/store'

export type EaterySection = 'info' | 'menu' | 'reviews'

type EateryMapModel = {
    show: boolean
    fullscreen: boolean
    loading: boolean
    eatery: EateryGuestInfo | EateryInfo | null
    section: EaterySection
    scrolling: boolean
}

const [eateryMap, setEateryMap] = createStore<EateryMapModel>({
    show: false,
    fullscreen: false,
    loading: true,
    eatery: null,
    section: 'info',
    scrolling: false,
})

async function fetch_eatery(gene: Gene, guest?: boolean) {
    if (!gene) return

    if (gene === eateryMap.eatery?.gene) {
        return setEateryMap({ show: true, loading: false })
    }

    setEateryMap({ show: true, loading: true, eatery: null })

    let res

    if (guest) {
        res = await api_guest_eateries_get({ gene })

        if (res.ok()) {
            setEateryMap({
                eatery: res.body,
            })
        }
    } else {
        res = await api_eateries_get({ gene })

        if (res.ok()) {
            setEateryMap({
                eatery: res.body,
            })
        }
        setEateryMap({ loading: false })
    }

    setEateryMap({ loading: false })
}

export { type EateryMapModel, eateryMap, setEateryMap, fetch_eatery }
