import {
    AdminEateryInfo,
    AdminEateryItem,
    AdminEateryUpdateInfoBody,
    Count,
    DishDaily,
    DishFlags,
    DishInfo,
    DishKind,
    Duration,
    EateryCategory,
    EateryFlags,
    EateryInfo,
    EateryTags,
    EateryTheme,
    EateryVibe,
    EntityFlags,
    PondInfo,
    Session,
    SessionInfo,
    UserInfo,
} from 'api'

export const ENTITY_FLAGS_DEFAULT: EntityFlags = {
    is_alive: false,
    is_edited: false,
    is_private: false,
}

export const SESSION_INFO_DEFAULT: SessionInfo = {
    client: 0,
    os: 0,
    browser: 0,
    device: 0,
    client_version: 0,
    os_version: 0,
    browser_version: 0,
}

export const SESSION_DEFAULT: Session = {
    ip: '',
    info: SESSION_INFO_DEFAULT,
    timestamp: 0,
    token: '',
}

export const USER_INFO_DEFAULT: UserInfo = {
    gene: null,
    agent: null,
    review: null,
    photo: null,
    reviews: [0, 0, 0],
    created_at: 0,
    phone: '',
    cc: 0,
    entity_flags: ENTITY_FLAGS_DEFAULT,
    is_banned: false,
    name: '',
    sessions: [SESSION_DEFAULT, SESSION_DEFAULT, SESSION_DEFAULT],
    session_index: 0,
}

export const DURATION_DEFAULT: Duration = {
    open: 0,
    close: 0,
}

export type Day = [Duration, Duration, Duration, Duration]
export const DAY_DEFAULT: Day = [
    DURATION_DEFAULT,
    DURATION_DEFAULT,
    DURATION_DEFAULT,
    DURATION_DEFAULT,
]
export type OpeningHours = [Day, Day, Day, Day, Day, Day, Day]
export const OPENING_HOURS_DEFAULT: OpeningHours = [
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
]

export const EATERY_CATEGORIES = [
    'unknown',
    'cafe',
    'restaurant',
    'fast_food',
    'seafood',
    'ice_cream',
    'dizi',
    'ash',
    'kababi',
    'kalle_pache',
    'liver',
] as const
type X = (typeof EATERY_CATEGORIES)[number]
export const __ECAS: X extends EateryCategory
    ? EateryCategory extends X
        ? true
        : 0
    : 0 = true

export const EATERY_THEMES = [
    'unknown',
    'family',
    'romantic',
    'sports',
    'friendly',
    'mafia',
    'business',
    'modern',
    'nature',
    'traditional',
    'luxury',
] as const
type T = (typeof EATERY_THEMES)[number]
export const __ETAS: T extends EateryTheme
    ? EateryTheme extends T
        ? true
        : 0
    : 0 = true

export const EATERY_TAGS_DEFAULT: EateryTags = {
    has_vegetarian_food: 'idk',
    is_suitable_for_children: 'idk',
    has_private_dining: 'idk',
    has_live_music: 'idk',
    is_suitable_for_large_groups: 'idk',
    is_suitable_for_pets: 'idk',
    has_dinner: 'idk',
    has_tv: 'idk',
    has_wifi: 'idk',
    has_outdoor_seating: 'idk',
    has_breakfast: 'idk',
    has_indoor_seating: 'idk',
    has_lunch: 'idk',
    has_parking: 'idk',
    has_solo_dining: 'idk',
    has_reservations: 'idk',
    has_takeaway: 'idk',
    has_toilet: 'idk',
    is_wheelchair_accessible: 'idk',
    has_dine_in: 'idk',
    has_delivery: 'idk',
    has_halal_food: 'idk',
    is_tourist_friendly: 'idk',
    is_suitable_for_family: 'idk',
    has_drive_through: 'idk',
    has_vegan_food: 'idk',
}

export const EATERY_FLAGS_DEFAULT: EateryFlags = {
    is_close: false,
    is_for_guest: false,
}

export const EATERY_VIBE_DEFAULT: EateryVibe = {
    is_cozy: false,
    is_casual: false,
    is_modern: false,
    is_natural: false,
    is_historic: false,
    is_romantic: false,
    is_beachside: false,
    is_luxurious: false,
    is_industrial: false,
}

export const EATERY_INFO_DEFAULT: EateryInfo = {
    gene: null,
    latitude: 0,
    longitude: 0,
    menu: null,
    review: null,
    detail: null,
    photos: [null, null, null, null, null, null, null],
    stars: [0, 0, 0, 0, 0],
    theme: 'unknown',
    entity_flags: ENTITY_FLAGS_DEFAULT,
    tags: EATERY_TAGS_DEFAULT,
    flags: EATERY_FLAGS_DEFAULT,
    vibe: EATERY_VIBE_DEFAULT,
    cc: 0,
    tables: 0,
    calc_zoom: 0,
    category: 'unknown',
    zoom: 0,
    phone: '',
    opening_hours: OPENING_HOURS_DEFAULT,
    name: '',
    priority: 0,
    quality_score: 0,
    score: 0,
}

export const ADMIN_EATERY_UPDATE_INFO_DEFAULT: AdminEateryUpdateInfoBody = {
    name: '',
    opening_hours: OPENING_HOURS_DEFAULT,
    phone: '',
    cc: 0,
    tags: null,
    vibe: null,
    flags: null,
    theme: 'unknown',
    tables: 0,
    category: 'unknown',
    is_private: true,
}

export const ADMIN_EATERY_INFO_DEFAULT: AdminEateryInfo = {
    ...EATERY_INFO_DEFAULT,
    extra: null,
}

export const ADMIN_EATERY_ITEM_DEFAULT: AdminEateryItem = {
    ...ADMIN_EATERY_INFO_DEFAULT,
    menu: null,
    review: null,
}

export const DISH_KIND = [
    'unknown',
    'food',
    'pizza',
    'burger',
    'dessert',
    'pasta',
    'icecream',
    'seafood',
    'soup',
    'ash',
    'kabab',
    'polo',
    'chicken',
    'steak',
    'sandwich',
    'salad',
    'fried',
    'fish',
    'sushi',
    'drink',
    'tea',
    'coffee',
    'soda',
    'mojito',
    'lemonade',
    'juice',
    'chocolate',
    'milk',
    'smoothie',
    'meat',
    'noodle',
    'sides',
] as const
type __DKAST = (typeof DISH_KIND)[number]
export const __DKAS: __DKAST extends DishKind
    ? DishKind extends __DKAST
        ? true
        : 0
    : 0 = true

export const DISH_FORM = ['unknown', 'food', 'drink']

export function isDishKind(value: string): value is DishKind {
    return DISH_KIND.includes(value as DishKind)
}

export function isCategory(value: string): value is EateryCategory {
    return EATERY_CATEGORIES.includes(value as EateryCategory)
}
export function isTheme(value: string): value is EateryTheme {
    return EATERY_THEMES.includes(value as EateryTheme)
}

export const DISH_FLAGS_DEFAULT: DishFlags = {
    is_breakfast: 'idk',
    is_dinner: 'idk',
    is_free: 'idk',
    is_offering: 'idk',
    is_spot_rate_price: 'idk',
    is_available: 'idk',
    is_lunch: 'idk',
}

export const DISH_DAILY_DEFAULT: DishDaily = {
    in_friday: false,
    is_daily: false,
    in_thursday: false,
    in_saturday: false,
    in_monday: false,
    in_tuesday: false,
    in_sunday: false,
    in_wednesday: false,
}

export const DAYS_OF_WEEK = [
    'saturday',
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
] as const

export const priorityOrder: DishKind[] = [
    'food',
    'polo',
    'pizza',
    'burger',
    'chicken',
    'steak',
    'pasta',
    'fish',
    'sushi',
    'seafood',
    'kabab',
    'sandwich',
    'ash',
    'soup',
    'fried',
    'salad',
    'drink',
] as const

export const SORT_ORDER = ['desc', 'asc'] as const

export const EATERY_SORT = ['review_count', 'star_sum', 'stars'] as const

export const FILTER_NUMBER_OPT = [
    'gt',
    'lt',
    'eq',
    'ne',
    'in',
    'not_in',
] as const

export const DISH_INFO_DEFAULT: DishInfo = {
    gene: null,
    pond: null,
    eatery: null,
    price: 0,
    photos: [null, null, null, null],
    kind: 'unknown',
    entity_flags: { ...ENTITY_FLAGS_DEFAULT, is_alive: true },
    name: '',
    note: '',
    flags: DISH_FLAGS_DEFAULT,
    form: 'unknown',
    daily: DISH_DAILY_DEFAULT,
}

export const COUNT_DEFAULT: Count = { alive: 0, total: 0, pages: 0 }

export const POND_INFO_DEFAULT: PondInfo = {
    gene: null,
    next: null,
    past: null,
    origin: null,
    alive: 0,
    empty: 0,
    is_free: false,
    entity_flags: ENTITY_FLAGS_DEFAULT,
}
