import { QuestionIcon } from '!/icons'
import { Component, createSignal, JSX, onCleanup, Show } from 'solid-js'
import { createStore } from 'solid-js/store'

import './style/popup.scss'

type popupTypes = 'success' | 'info' | 'error' | 'warning'

type PopupProps = {
    show: boolean

    type: popupTypes
    Icon: () => JSX.Element | null
    title: string
    content: string

    timer: number

    onSubmit: () => void
    onReject: () => void

    class?: string
}

const DEFAULT_POPUP: PopupProps = {
    show: false,
    type: 'info',
    Icon: null,
    title: '',
    timer: 0,
    content: '',
    onReject: () => {},
    onSubmit: () => {},

    class: '',
}

const [popup, setPopup] = createStore<PopupProps>({ ...DEFAULT_POPUP })

export const Popup: Component = props => {
    const [canSubmit, setSubmit] = createSignal(false)

    let ref: HTMLElement

    let interval: ReturnType<typeof setTimeout>
    let interval2: ReturnType<typeof setTimeout>

    // createEffect(() => {
    //     if (!ref || !popup.show) return

    // })

    onCleanup(() => {
        clearTimeout(interval)
        clearTimeout(interval2)
    })

    let startY: number

    const onTouchStart = e => {
        startY = e.touches[0].clientY

        e.currentTarget.style.transition = 'none'
    }

    const onTouchMove = e => {
        const deltaY = e.touches[0].clientY - startY
        e.currentTarget.style.transform = `translateY(${deltaY}px)`
        e.currentTarget.style.opacity = `${Math.max(0.1, 1 - Math.abs(deltaY) / 100)}`
    }

    const onTouchEnd = e => {
        const deltaY = e.changedTouches[0].clientY - startY

        if (Math.abs(deltaY) > 80) {
            setPopup({ ...DEFAULT_POPUP })
            popup.onReject()
            interval2 = setTimeout(() => {
                ref.removeAttribute('style')
            }, 200)
        } else {
            e.currentTarget.style.transition = ''
            e.currentTarget.style.transform = ''
            e.currentTarget.style.opacity = ''
        }
    }

    return (
        <div
            class={`popup-container ${popup.type} ${popup.class} `}
            classList={{ show: popup.show }}
            onclick={() => {
                popup.onReject()
                setPopup({ ...DEFAULT_POPUP })
            }}
        >
            <form
                ref={e => (ref = e)}
                onclick={e => {
                    e.stopImmediatePropagation()
                    e.stopPropagation()
                }}
                onsubmit={e => {
                    e.preventDefault()

                    if (!canSubmit()) return

                    popup.onSubmit()
                    setPopup({ ...DEFAULT_POPUP })
                }}
                onreset={e => {
                    e.preventDefault()

                    popup.onReject()
                    setPopup({ ...DEFAULT_POPUP })
                }}
                ontouchstart={onTouchStart}
                ontouchmove={onTouchMove}
                ontouchend={onTouchEnd}
                class='popup-wrapper'
            >
                <div class='icon'>
                    <Show when={popup.Icon} fallback={<QuestionIcon />}>
                        <popup.Icon />
                    </Show>
                </div>

                <h3 class={innerWidth < 769 ? `title` : 'section_title'}>
                    {popup.title}
                </h3>
                <p class={innerWidth < 769 ? `title_smaller` : 'title_small'}>
                    {popup.content}
                </p>

                <div
                    class='ctas'
                    ontouchstart={e => {
                        e.stopPropagation()
                    }}
                    ontouchmove={e => {
                        e.stopPropagation()
                    }}
                >
                    <button class='cta submit title_smaller' type='submit'>
                        <div
                            class='line'
                            style={{ 'animation-duration': `${popup.timer}s` }}
                            onanimationstart={() => setSubmit(false)}
                            onanimationend={() => setSubmit(true)}
                            classList={{ anim: popup.show }}
                        ></div>
                        تایید
                    </button>
                    <button type={'reset'} class='cta reject title_smaller'>
                        لغو
                    </button>
                </div>
            </form>
        </div>
    )
}

export { popup, setPopup }
