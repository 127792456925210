import { getDB, setDB } from '!/db'
import { createEffect, createRoot } from 'solid-js'
import { createStore } from 'solid-js/store'
import { USER_COOKIE_TOKEN, USER_GUEST } from './dbLabels'
import { api_user_get, api_user_logout_post, UserInfo } from '!/api'

export type SelfModel = {
    loading: boolean
    fetch: boolean
    user: UserInfo
    guest: boolean
    login: boolean
}

const [self, setSelf] = createStore<SelfModel>({
    loading: true,
    fetch: false,
    user: null,
    guest: false,
    login: false,
})

// Initialize user and handle loading state
;(async () => {
    let is_guest = await getDB(USER_GUEST)

    if (is_guest === 'true') {
        setSelf({
            fetch: false,
            guest: true,
            loading: false,
            user: null,
        })
    }

    try {
        fetch_user()
    } catch {
        set_guest()
    }
})()

async function fetch_user() {
    let db_cookie = await getDB(USER_COOKIE_TOKEN)

    let res = await api_user_get({
        headers: {
            ...(db_cookie && {
                authorization: db_cookie,
                'Content-Type': '',
            }),
        },
        show_alert: false,
    })

    if (res.ok()) {
        let user = res.body

        let { gene, session_index, sessions } = user

        setSelf({
            user,
            loading: false,
            guest: false,
            fetch: false,
        })
        setDB(USER_GUEST, '')
        setDB(
            USER_COOKIE_TOKEN,
            `user ${gene}:${session_index}:${sessions[session_index].token}`
        )
    } else {
        set_guest()

        return
    }
}

const set_guest = () => {
    setSelf({
        user: null,
        loading: false,
        guest: true,
        fetch: false,
    })
    setDB(USER_GUEST, 'true')
    setDB(USER_COOKIE_TOKEN, '')
}

async function logout_user(): Promise<void> {
    setDB(USER_COOKIE_TOKEN, {})
    setDB(USER_GUEST, 'true')
    setSelf(null)

    await api_user_logout_post()
}

createRoot(() => {
    createEffect(() => {
        if (!self.fetch) return

        fetch_user()
    })
})

export { self, setSelf, fetch_user, logout_user }
