import './preload'

import { Alerts, Logo, PlzLogin, Popup } from 'comps'
import Navbar from 'layout/navbar'
import {
    Component,
    createSignal,
    lazy,
    onCleanup,
    onMount,
    Show,
} from 'solid-js'
import { render } from 'solid-js/web'
import { activeTab, self } from './store'

const Home = lazy(() => import('pages/home'))
const Map = lazy(() => import('pages/map'))
const Account = lazy(() => import('pages/account'))
const Login = lazy(() => import('pages/login'))
const AddHome = lazy(() => import('comps/addHome'))

import './style/base.scss'
import './style/config.scss'
import './style/pcmode.scss'
import './style/theme.scss'
import { map } from 'pages/map'

const APP_ID = 'gooje-offical-pwa'

export type MapChilrenType = {
    active: boolean
}

export const App = () => {
    const [deferredPrompt, setDeferredPrompt] = createSignal<any | null>(null)

    onMount(() => {
        if ('serviceWorker' in navigator) {
            // Register from root scope
            navigator.serviceWorker
                .register('/sw.js')
                .then(registration => {
                    if (navigator.onLine) {
                        registration.update()
                    }
                })
                .catch(error => {
                    console.error('SW registration failed:', error)
                })
        }

        // document.addEventListener(
        //     'contextmenu',
        //     function (e) {
        //         e.preventDefault()
        //     },
        //     false
        // )

        window.addEventListener('beforeinstallprompt', e => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault()
            // Save the event because you'll need to trigger it later.
            setDeferredPrompt(e)
        })

        onCleanup(() => {
            if (map) {
                map.remove()
            }
        })
    })

    let isInWebAppiOS =
        /* @ts-ignore */
        'standalone' in window.navigator && window.navigator.standalone === true

    let isInWebAppChrome = window.matchMedia(
        '(display-mode: standalone)'
    ).matches

    let isOnSafari =
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
    let isOnChrome = /Chrome/.test(navigator.userAgent)

    /* @ts-ignore */
    let isIos = /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream
    let isAndroid = /Android/.test(navigator.userAgent)

    /* Determine if the app is installed */
    let showAddHomeBanner =
        (isIos && isOnSafari && !isInWebAppiOS) ||
        (isAndroid && isOnChrome && !isInWebAppChrome)

    return (
        <Show when={!self.loading} fallback={<LoadingPage />}>
            <Show when={!self.login} fallback={<Login />}>
                <Show when={showAddHomeBanner}>
                    <AddHome deferredPrompt={deferredPrompt()} />
                </Show>

                <div class='main-containers'>
                    <Map active={activeTab() === 'map'} />
                    <Account active={activeTab() === 'account'} />
                </div>

                <Navbar />
            </Show>
            <Alerts />
            <Popup />
            <PlzLogin />
        </Show>
    )
}

const TEXTS = [
    '',
    'سلام!',
    'خوش',
    'آمدید',
    'درحال',
    'اتصال',
    'به گوجه',
    'هستید...',
    'معمولا',
    'انقدر',
    'طول',
    'نمیکشه',
    'اینترنتتون',
    'وصله؟',
]
const LoadingPage: Component = () => {
    const [active, setActive] = createSignal(0)

    let interval: ReturnType<typeof setInterval>

    onMount(() => {
        interval = setInterval(() => {
            setActive(s => (s + 1) % TEXTS.length)
        }, 1000)

        onCleanup(() => clearInterval(interval))
    })

    return (
        <div class='loading-page'>
            <Logo />
            <div class='text title'>
                <span>{TEXTS[active()]}</span>
            </div>
        </div>
    )
}

render(() => <App />, document.getElementById('root'))
